"use client";
import { notFound } from 'next/navigation';
import style from '@/assets/styling/scss/storypage.module.scss';
import { doFetch } from '@/app/utils';
import { ScrollToTop, GoBack, StoryContent } from '@/components/elements';
import { NavBar, Footer } from '@/components/partials';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {paperVariants} from '@/assets/utils/motion';

interface Story {
  id: string;
  information: object;
  image: object;
  gallery: object;
  partner: object;
}

async function getStoryData(id: string): Promise<Story | null> {
  const { data: { data } } = await doFetch(`/api/stories/${id}?populate=*`, 'GET');
  return data ? {
    id: data.id,
    information: data.attributes.information,
    image: data.attributes.image.data,
    gallery: data.attributes.gallery.data,
    partner: data.attributes.partner.data.attributes
  } : null;
}

const StoryPage = ({ id }: { id: string }) => {
  const [story, setStory] = useState<Story | null>(null);

  useEffect(() => {
    async function fetchStory() {
      const storyData = await getStoryData(id);
      if (!storyData) {
        notFound();
      } else {
        setStory(storyData);
      }
    }

    fetchStory();
  }, [id]);

  if (story === null) return null;

  return (
    <>
      <ScrollToTop />
      <NavBar />
      <main>
        <motion.section 
          className={style.content} 
          variants={paperVariants}
          initial="hidden"
          animate={["visible", "scale", "translateY"]}
          exit="exit"
        >
          <GoBack />
          {story && <StoryContent {...story} />}
        </motion.section>
      </main>
      <Footer />
    </>
  );
};

export default StoryPage;
