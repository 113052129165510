"use client";

import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import styles from '@/assets/styling/scss/accordion.module.scss';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import Image from 'next/image';
import { FormattedMessage } from '@/components/elements';

const AccordionMenu: React.FC = () => (
  <Accordion.Root className={styles.Root} type="single" defaultValue="item-1" collapsible>
    <Accordion.Item className={styles.Item} value="item-1">
      <AccordionTrigger>
        <Image src="/images/palette.png" width="25" height="25" alt="Branding Icon" />
        <FormattedMessage id="brand_customization" />
      </AccordionTrigger>
      <AccordionContent>
        <FormattedMessage id="brand_customization_description" />
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className={styles.Item} value="item-2">
      <AccordionTrigger>
        <Image src="/images/update.png" width="25" height="25" alt="Notification Icon" />
        <FormattedMessage id="ota_daemon" />
      </AccordionTrigger>
      <AccordionContent>
        <FormattedMessage id="ota_daemon_description" />
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className={styles.Item} value="item-3">
      <AccordionTrigger>
        <Image src="/images/bell.png" width="25" height="25" alt="Notification Icon" />
        <FormattedMessage id="push_notifications" />
      </AccordionTrigger>
      <AccordionContent>
        <FormattedMessage id="push_notifications_description" />
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className={styles.Item} value="item-4">
      <AccordionTrigger>
        <Image src="/images/lock.png" width="25" height="25" alt="Notification Icon" />
        <FormattedMessage id="security" />
      </AccordionTrigger>
      <AccordionContent>
        <FormattedMessage id="security_description" />
      </AccordionContent>
    </Accordion.Item>
  </Accordion.Root>
);

const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<typeof Accordion.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className={styles.Header}>
    <Accordion.Trigger
      className={classNames(styles.Trigger, className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ChevronDownIcon className={styles.Chevron} aria-hidden />
    </Accordion.Trigger>
  </Accordion.Header>
));

AccordionTrigger.displayName = 'AccordionTrigger';

const AccordionContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof Accordion.Content>
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={classNames(styles.Content, className)}
    {...props}
    ref={forwardedRef}
  >
    <div className={styles.ContentText}>{children}</div>
  </Accordion.Content>
));

AccordionContent.displayName = 'AccordionContent';

export default AccordionMenu;
