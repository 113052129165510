// stores/DocStore.ts
import { makeObservable, observable, action, runInAction } from 'mobx';
import { gatherFilesContentFromParsedDocs } from '@/assets/utils/utils';

interface NavItem {
  name: string;
  type: string;
  children?: NavItem[];
  path?: string;
  content?: string;
}

class DocStore {
  navItems: NavItem[] = [];
  filteredNavItems: NavItem[] = [];
  selectedFile: string = '';
  activePath: string = '';
  searchQuery: string = '';

  constructor() {
    makeObservable(this, {
      navItems: observable,
      filteredNavItems: observable,
      selectedFile: observable,
      activePath: observable,
      searchQuery: observable,
      setNavItems: action,
      setActivePath: action,
      setSelectedFile: action,
      setSearchQuery: action,
      findFileByName: action,
      handleFileSelect: action,
      findFirstFile: action,
    });
  }

  setNavItems(items: NavItem[]): void {
    this.navItems = items;
    this.filteredNavItems = items; 
  }

  setActivePath(path: string): void {
    runInAction(() => (this.activePath = path));
  }

  setSelectedFile(content: string, path: string): void {
    runInAction(() => {
      this.selectedFile = content;
      this.activePath = path;
    });
  }

  setSearchQuery(query: string): void {
    this.searchQuery = query;
    this.filteredNavItems = this.navItems.filter(item =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
  }

  findFileByName(name: string, filteredNavItems: NavItem[]): void {
    const flattenFiles = gatherFilesContentFromParsedDocs(filteredNavItems);
    const file = flattenFiles.find(file => file.name === name);
    if (file) {
      runInAction(() => {
        this.activePath = file.path || '';
        this.selectedFile = file.content || '';
      });
    }
  }

  handleFileSelect(name: string, filteredNavItems: NavItem[]): void {
    console.log("Selected file name:", name);
    // this.findFileByName(name, filteredNavItems); // Search by name
  }

  findFirstFile(navItems: NavItem[]): NavItem | null {
    if (navItems.length === 0) return null;
    for (const item of navItems) {
      if (item.type === 'file') {
        return item;
      }
      if (item.children) {
        const found = this.findFirstFile(item.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }
}

export default new DocStore();
