import style from '@/assets/styling/scss/homepage.module.scss';
import { AccordionMenu } from '@/components/elements';
import Image from 'next/image';
import { FormattedMessage } from "@/components/elements";

const BlueApp = () => {
  return (
    <section className={style.blueApp}>
      <h1>
        <FormattedMessage id="the_blueapp" />
      </h1>
      <h2>
        <FormattedMessage id="our_cherry_on_top" />
      </h2>

      <div className={style.contentWrapper}>
        <AccordionMenu />
        <div className={style.mockupWrapper}>
          <Image
            className={style.mockup}
            src="/images/blueapp_mockup.png"
            width="217"
            height="535"
            alt="BlueApp Mockup"
          />
        </div>
      </div>
    </section>
  );
};

export default BlueApp;

