"use client";

import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from './';
import style from '../../assets/styling/scss/button.module.scss';

type Props = {
  text: string;
  icon?: JSX.Element;
  preIcon?: JSX.Element;
  onClick?: () => void;
  replacements?: { [key: string]: string };
  disabled?: boolean;
  color?: string;
  link?: string;
};

const Button: FC<Props> = observer(({ text, preIcon, icon, onClick, replacements, disabled, color, link }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  function handleLink() {
    if (link) {
      window.location.href = link;
    }
  }

  if (!isMounted) {
    return null;
  }

  return (
    <div
      className={style.button}
      style={{ backgroundColor: color }}
      onClick={link ? handleLink : onClick}
      aria-disabled={disabled}
    >
      {preIcon && <span className={style.preIcon}>{preIcon}</span>}
      <span>
        <FormattedMessage id={text} replacements={replacements} />
      </span>
      {icon && <span className={style.icon}>{icon}</span>}
    </div>
  );
});

export default Button;
