"use client";
import { ArrowRight } from "lucide-react";
import { FC } from 'react';
import style from '@/assets/styling/scss/stories.module.scss';
import Link from 'next/link';
import { observer } from 'mobx-react';
import { globalStore } from '@/app/stores';
import { FormattedMessage } from "@/components/elements";
import { motion } from 'framer-motion';

interface ImageAttributes {
  caption: string;
  url: string;
  width: number;
  height: number;
}

interface InformationAttributes {
  name: string;
  title: string;
  description: string;
  project: string;
  possibilities: string;
  applications: string;
}

interface StoryProps {
  id: string;
  information: InformationAttributes;
  image: ImageAttributes;
}

const childVariants = {
  hidden: { opacity: 0, y: 30, scale: 0.95 },
  visible: { 
    opacity: 1, 
    y: 0, 
    scale: 1,
    transition: {
      duration: 0.3,  
      ease: [0.25, 0.8, 0.5, 1], 
    }
  },
};

const Story: FC<StoryProps> = observer(({ id, image, information }) => {
  const { lang } = globalStore;
  const { name, title, description } = information;
  const { caption, url, width, height } = image;

  return (
    <motion.article
      variants={childVariants}
      className={style.story}
      onClick={() => (window.location = `/for-companies/success-stories/${id}`)}
    >
      <div className={style.imageWrapper}>
        <img
          className={style.image}
          src={url}
          width={width}
          height={height}
          alt={caption}
        />
      </div>
      <h3 className={style.name}>{name.toUpperCase()}</h3>
      <h2 className={style.title}>{title[lang]}</h2>
      <p className={style.description}>{description[lang]}</p>
      <Link
        className={style.button}
        href={`/for-companies/success-stories/${id}`}
        scroll={true}
      >
        <FormattedMessage id="read_more" />
        <ArrowRight />
      </Link>
    </motion.article>
  );
});

export default Story;
