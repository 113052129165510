"use client";
import { useState, useEffect, useRef } from 'react';
import Head from 'next/head';
import style from '@/assets/styling/scss/homepage.module.scss';

const VideoPlayer = () => {
  const [videoIndex, setVideoIndex] = useState(0);
  const videoRef = useRef(null);

  const videoSources = [
    '/videos/dpt_movie_low_720_part1.mp4',
    '/videos/dpt_movie_low_720_part2.mp4',
    '/videos/dpt_movie_low_720_part3.mp4',
  ];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * videoSources.length);
    setVideoIndex(randomIndex);
  }, []);

  useEffect(() => {
    const currentVideo = videoSources[videoIndex];

    if (videoRef.current) {
      videoRef.current.src = currentVideo;

      const handleLoadedMetadata = () => {
        const randomTime = Math.random() * videoRef.current.duration;
        videoRef.current.currentTime = randomTime;
        videoRef.current.play();
        videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };

      videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
  }, [videoIndex]);

  const handleVideoEnded = () => {
    setVideoIndex((prevIndex) => {
      let nextIndex;
      do {
        nextIndex = Math.floor(Math.random() * videoSources.length);
      } while (nextIndex === prevIndex); 
      return nextIndex;
    });
  };

  const seoData = {
    title: 'BlueCherry valued customers',
    description: 'Capturing the scene and concentrators',
    thumbnailUrl: '/images/thumbnail.jpg',
    uploadDate: '2024-10-07',
    duration: 'PT3M',
    contentUrl: videoSources[videoIndex],
    embedUrl: videoSources[videoIndex],
  };

  return (
    <div className={style.videoContainer}>
      <Head>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            name: seoData.title,
            description: seoData.description,
            thumbnailUrl: seoData.thumbnailUrl,
            uploadDate: seoData.uploadDate,
            duration: seoData.duration,
            contentUrl: seoData.contentUrl,
            embedUrl: seoData.embedUrl,
          })}
        </script>
      </Head>
      <video
        ref={videoRef}
        autoPlay
        muted
        className={style.heroVideo}
        onEnded={handleVideoEnded}
        poster={seoData.thumbnailUrl}
      >
        <source src={videoSources[videoIndex]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
