import(/* webpackMode: "eager", webpackExports: ["Root","List","Item","Trigger","Content","Indicator","Viewport","Link"] */ "/app/node_modules/@radix-ui/react-navigation-menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/react-fast-marquee/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styling/scss/homepage.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styling/scss/explainer.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styling/scss/input.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styling/scss/bluecherry.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styling/scss/markdown.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styling/scss/navigationmenu.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styling/scss/footer.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styling/scss/partner.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styling/scss/legal.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/assets/utils/utils.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/AccordionMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/AccordionQuestions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/Button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/elements/FormattedMessage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/GoBack.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/Hamburger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/ImageCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/LanguageToggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/Platform.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/ScrollToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/SearchWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/SensorStory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/SideNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/Stories.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/Story.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/StoryContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/elements/VideoPlayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/Documentation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/DownloadBlueCherry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/Pricing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/StoryPage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/SubmitRequest.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/SuccessStories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/Vouchers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/partials/NavBar.tsx");
