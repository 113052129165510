"use client";
import style from '@/assets/styling/scss/homepage.module.scss';
import Image from 'next/image';
import { Button } from '@/components/elements';
import {FormattedMessage}from '@/components/elements';

const SensorStory = () => {

  function handleSubmit() {
    window.location.href = "/for-companies/submit-a-request";
  }

  return (
<section className={style.sensorStory}>
      <h1><FormattedMessage id="home_to_sme_industry" /></h1>
      <h2><FormattedMessage id="start_small_think_big" /></h2>

      <figure className={`${style.figure} ${style.figureOne}`}>
        <figcaption>
          <FormattedMessage id="sensor_figure_caption_one" />
        </figcaption>
        <Image
          src="/images/home.png"
          width={193}
          height={271}
          alt="A shot in a kitchen with table and temperature sensor"
        />
      </figure>

      <figure className={`${style.figure} ${style.figureTwo}`}>
        <Image
          src="/images/industry.png"
          width={460}
          height={300}
          alt="A shot in a mechanic room in a boat"
        />
        <figcaption>
          <FormattedMessage id="sensor_figure_caption_two" />
        </figcaption>
      </figure>

      <p className="leadText">
        <FormattedMessage id="lead_text_part1" />
        <span>
        <FormattedMessage id="lead_text_part2" />
        </span>
      </p>

      <div className={style.ctaSubmit}>
        <Image
          src="/images/lightbulb.png"
          width={60}
          height={60}
          alt="A lightbulb icon"
        />

        <h2><FormattedMessage id="cta_text" /></h2>
        <Button text="submit_a_request" onClick={handleSubmit} color="var(--accent-secondary)" link="/for-companies/submit-a-request" />
      </div>
    </section>
  )
}

export default SensorStory;
