"use client"
import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import style from '@/assets/styling/scss/storycontent.module.scss';
import { globalStore } from '@/app/stores';
import { FormattedMessage, ImageCarousel } from '@/components/elements';

interface ImageAttributes {
  id: string;
  attributes: {
    url: string;
    caption: string;
    src: string;
    width: number;
    height: number;
  }
}

interface InformationAttributes {
  title: string;
  name: string;
  description: string;
  website: string;
  project: string;
  possibilities: string;
  gallery: string;
}

interface PartnerAttributes {
  partner_name: string;
  region: string;
  description: string;
  website_url: string;
}

interface StoryContentProps {
  id: string;
  information: InformationAttributes;
  image: ImageAttributes[];
  gallery: ImageAttributes[];
  partner: PartnerAttributes;
}

const Paragraph = ({ title, text }: {title: string; text: string}) => (
  <div className={style.paragraph}>
    <h3 className={style.title}>
      <FormattedMessage id={title} />
    </h3>
    <p className={style.text}>{text}</p>
  </div>
)

const ParagraphWithLink = ({ title, text, link }: {title: string; text: string; link: string;}) => (
  <div className={style.paragraph}>
    <h3 className={style.title}>{title}</h3>
    <a  
      className={style.link} 
      href={link.startsWith('htt') ? link : `https://${link}`}
      target="_blank"
      rel="noopener noreferrer"
      >{text}
      </a>
  </div>
)
const StoryContent: FC<StoryContentProps> = observer(({ id, information, image, gallery, partner }) => {
  const { lang, setCrumb } = globalStore;
  const { title, name, description, website, project, possibilities, applications } = information;
  const { url, caption, width, height } = image.attributes;
  const { partner_name, website_url } = partner;
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);

  useEffect(() => {
    setCrumb(name);
  }, [name, setCrumb]);

  function handleClickImage(idx: number) {
    setSelectedImageIndex(idx);
  }

  function handleImageCarousel() {
    setSelectedImageIndex(null);
  }

  return (
    <>
      <div className={style.wrapper} key={id}>
        <img
          className={style.image}
          src={url}
          width={width}
          height={height}
          alt={caption}
        />
        <div className={style.headerContent}>
          <h3 className={style.name}>{name}</h3>
          <h2 className={style.title}>{title[lang]}</h2>
          <a
            className={style.website}
            href={website.startsWith('htt') ? website : `https://${website}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {website}
          </a>

          <p className={style.text}>
            {description[lang]}
          </p>
        </div>
      </div>

      <Paragraph title="project" text={project[lang]} />
      <Paragraph title="possibilities" text={possibilities[lang]} />
      <Paragraph title="applications" text={applications[lang]} />

      <div className={style.imageWrapper}>
        {
          gallery.map(({ id, attributes }: ImageAttributes, idx: number) => (
            <img
              key={id}
              className={style.galleryImage}
              src={attributes.url}
              width={attributes.width}
              height={attributes.height}
              alt={attributes.caption}
              onClick={() => handleClickImage(idx)}
            />
          ))
        }
      </div>

      {
        selectedImageIndex !== null && (
          <ImageCarousel
            images={gallery.map(({ attributes }: ImageAttributes) => attributes)}
            initialIndex={selectedImageIndex}
            handleImageCarousel={handleImageCarousel}
          />
        )
      }

      <ParagraphWithLink title="Signature partner" text={partner_name} link={website_url} />
    </>
  );
});

export default StoryContent;

