"use client"
import { useEffect, useState } from 'react';
import { NavBar, Footer } from "@/components/partials";
import { Button } from '@/components/elements'; // Assuming this is your button component
import style from '@/assets/styling/scss/downloadbluecherry.module.scss';
import Image from 'next/image';

const DownloadIcon = () => (
  <Image src="/images/download.svg" alt="Download Icon" width={24} height={24} />
);

const getOS = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) return "Windows";
  if (/android/i.test(userAgent)) return "Android";
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
  if (/Macintosh/.test(userAgent)) return "MacOS";
  if (/Linux/.test(userAgent)) return "Linux";

  return "Unknown";
};

const DownloadBlueCherry = () => {
  const [os, setOS] = useState('');

  useEffect(() => {
    const detectedOS = getOS();
    setOS(detectedOS);
  }, []);

  // Determine download URL based on OS
  const getPrimaryDownloadLink = () => {
    switch (os) {
      case 'Windows':
        return '/downloads/bluecherry-windows.zip'; // Adjust the path as needed
      case 'MacOS':
        return '/downloads/bluecherry-macos.zip';
      case 'Linux':
        return '/downloads/bluecherry-linux.zip';
      default:
        return '#'; // Fallback if OS is unknown
    }
  };

  // Determine secondary download links
  const getSecondaryLinks = () => {
    const links = {
      Linux: '/downloads/bluecherry-linux.zip',
      Windows: '/downloads/bluecherry-windows.zip',
      MacOS: '/downloads/bluecherry-macos.zip',
    };

    delete links[os];
    return links;
  };

  const secondaryLinks = getSecondaryLinks();

  return (
    <>
      <NavBar />
      <main>
        <section className={style.content}>
          <h1>Download BlueCherry</h1>
          <h2>The BlueCherry client is available for Linux, Windows, and MacOS</h2>
          <div className={style.buttons}>
            <Button
              text="Bluecherry Downloaden"
              preIcon={<DownloadIcon />}
              onClick={() => window.location.href = getPrimaryDownloadLink()}
            />
            <div className={style.secondaryButtons}>
              {Object.entries(secondaryLinks).map(([osName, link]) => (
                <a key={osName} href={link} download className={style.secondaryButton}>
                  Download for {osName}
                </a>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default DownloadBlueCherry;

