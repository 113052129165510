"use client";
import style from '@/assets/styling/scss/stories.module.scss';
import { doFetch } from '@/app/utils';
import { Story } from '.';
import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const containerVariants = {
  hidden: { opacity: 0.2, scale: 0.95 }, 
  visible: {
    opacity: 1,
    scale: 1, 
    transition: {
      staggerChildren: 0.05,  
      when: "beforeChildren",
      duration: 0.4,  
    },
  },
};
async function getStoryDataById(id) {
  const { data: { data } } = await doFetch(`/api/stories/${id}?populate=*`, 'GET');
  return {
    id: data.id,
    information: data.attributes.information,
    image: data.attributes.image.data.attributes,
    gallery: data.attributes.gallery.data,
  };
}

export default function Stories() {
  const [stories, setStories] = useState([]);

  useEffect(() => {
    async function getStories() {
      const storiesIds = [1, 2, 4];
      const storyPromises = storiesIds.map(id => getStoryDataById(id));
      const storiesData = await Promise.all(storyPromises);
      setStories(storiesData);
    }

    getStories();
  }, []);

  if (stories.length === 0) return null;

  return (
    <AnimatePresence>
      <motion.section
        className={style.stories}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        {stories.map(story => (
          <Story key={story.id} {...story} />
        ))}
      </motion.section>
    </AnimatePresence>
  );
}
