"use client";
import style from "@/assets/styling/scss/navbar.module.scss";
import { NavigationMenu, Logo, LanguageToggle, FormattedMessage, Hamburger, SearchWrapper } from "../elements";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useMediaQuery } from "@/assets/utils/utils";
import { useEffect, useState } from "react";
import { globalStore } from "@/app/stores";
import { translate, useScrollDirection } from "@/assets/utils/utils";
import { observer } from 'mobx-react';

const LINKS = {
  stories: "subtitle_stories",
  request: "subtitle_request",
  pricing: "subtitle_pricing",
  partner: "subtitle_partner",
  bluecherry: "subtitle_bluecherry",
  documentation: "subtitle_documentation",
  dlbc: "subtitle_download_bluecherry",
  voucher: "subtitle_voucher",
  companies: "subtitle_companies",
  developers: "subtitle_developers",
  legal: "subtitle_legal",
  terms: "subtitle_terms_of_conditions",
  privacy: "subtitle_privacy"
};

const getTitleFromLink = (path) => {
  const key = Object.keys(LINKS).find((key) => path.includes(key));
  return key ? LINKS[key] : "";
};

const getBreadcrumbs = (path = "") => {
  const parts = path.split("/").filter(Boolean);
  return parts.map((part, index) => {
    const href = `/${parts.slice(0, index + 1).join("/")}`;
    return { href, label: part };
  });
};

const translateTitle = (key) => {
  return <FormattedMessage id={key} />;
};

const translaterTitle = (key) => {
  return translate(globalStore.lang, key);
};

const NavBar = observer(() => {
  const { crumbName,  navOn} = globalStore;
  const [isOpen, setOpen] = useState(false);
  const scrollDirection = useScrollDirection();
  const isSmallDevice = useMediaQuery("only screen and (max-width: 1024px)");

  const pathName = usePathname();
  const isHomePage = pathName === "/";
  const isDetailPage = pathName.startsWith("/for-companies/success-stories/") && pathName.split("/").length > 2;
  const breadcrumbs = getBreadcrumbs(pathName);

  useEffect(() => {},[navOn])

  if(!navOn) {
    return(
      <></>
    )
  }

  return (
    <nav className={`${style.navigation} ${scrollDirection === "down" ? style.hidden : ""}`}>
      <div className={style.container}>
        <Logo styling={{ position: "absolute", left: "1.5rem" }} />

        {isSmallDevice && (
          <Hamburger isOpen={isOpen} toggleBurger={() => setOpen(!isOpen)} />
        )}

        {!isSmallDevice && (
          <>
            <NavigationMenu />

            <SearchWrapper />

            <a
              href="https://sso.bluecherry.io/"
              target="_blank"
              rel="noopener noreferrer"
              className={style.loginLink}
            >
              <FormattedMessage id="login_link_text" />
            </a>

            <Link href="/for-companies/submit-a-request" className={style.submitButton}>
              <FormattedMessage id="start_your_project" />
            </Link>

            <LanguageToggle long={false} />
          </>
        )}
      </div>

      {!isHomePage && !isOpen && (
        <ul className={`${style.subNavigation} ${scrollDirection === "down" ? style.hidden : ""}`}>
          <div className={style.subContainer}>
            {breadcrumbs.map((crumb, index) => {
              const title = getTitleFromLink(crumb.label);
              const isSpecialPath = crumb.label === "for-companies" || crumb.label === "for-developers" || crumb.label === "legal";
              return (
                <span key={crumb.href}>
                  {index > 0 && <span style={{ color: "white" }}>&nbsp; &gt; &nbsp;</span>}
                  <Link href={isSpecialPath ? "/" : crumb.href}>
                    {translateTitle(title) || crumb.label}
                  </Link>
                </span>
              );
            })}
            {isDetailPage && crumbName && <Link href="#">{crumbName}</Link>}
          </div>
        </ul>
      )}
    </nav>
  );
});

export default NavBar;
