import style from '@/assets/styling/scss/input.module.scss';
import React, { FC } from 'react';
import { FormattedMessage } from "@/components/elements";
import { translate } from "@/assets/utils/utils";
import { globalStore } from "@/app/stores";

interface TextAreaProps {
  name: string;
  labelId: string;
  placeholderId: string;
  error?: string;
  fullWidth: boolean;
}

const TextArea: FC<TextAreaProps> = (({ name, labelId, placeholderId, error, fullWidth }) => {
  const { lang } = globalStore;

  return (
    <div className={style.textAreaWrapper} style={fullWidth ? {gridColumn: '1/-1'} : null}>
      <label className={style.label}>
        <FormattedMessage id={labelId} />
      </label>

      <div className={style.inputContainer}>
        <textarea
          {...name}
          className={style.textarea}
placeholder = { translate(lang, placeholderId) }     
        / >
      </div>

      {error && <p className={style.error}>{error}</p>}
    </div>
  );
});

export default TextArea;
