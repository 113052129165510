"use client";
import React, { FC, useEffect, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { ChevronDownIcon, GlobeIcon } from '@radix-ui/react-icons';
import style from '@/assets/styling/scss/popover.module.scss';
import { globalStore } from '@/app/stores';
import { observer } from 'mobx-react';
import { useDebounce } from "@uidotdev/usehooks";

interface LanguageToggleProps {
  align?: string;
  sideOffset?: number;
  long?: boolean;
}

const LanguageToggle: FC<LanguageToggleProps> = observer(({ align = "center", sideOffset = 0, long = true }) => {
  const { lang, setLang, languages, getFullLang } = globalStore;
  const [open, setOpen] = useState(false);
  const [mounted, setMounted] = useState(false); // State to track if component is mounted on the client

  const debouncedOpen = useDebounce(open, 200);

  const handleClick = (lang: string) => {
    setLang(lang);
    setOpen(false);
  };

  // UseEffect to set mounted state to true after the component has mounted
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null; // or a loading state to prevent mismatch during SSR
  }

  return (
    <Popover.Root open={debouncedOpen} onOpenChange={setOpen}>
      <Popover.Trigger
        asChild
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div className={long ? style.languageToggle : style.languageToggleSmall}>
          <GlobeIcon />
          {long ? <p>{getFullLang()}</p> : <p>{lang.toUpperCase()}</p>}
          <ChevronDownIcon className={style.chevronIcon} />
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={style.PopoverContent}
          sideOffset={sideOffset}
          align={align}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <div className={style.content}>
            <ul>
              {languages.map((language, i) => {
                if (language.short === lang) {
                  return (
                    <li key={i} className={style.activeLanguage}>
                      {language.long} ({language.short.toUpperCase()}){" "}
                    </li>
                  );
                } else {
                  return (
                    <li
                      key={i}
                      className={style.language}
                      onClick={() => handleClick(language.short)}
                    >
                      {language.long} ({language.short.toUpperCase()}){" "}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
});

export default LanguageToggle;
