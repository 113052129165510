// "use client"; // Ensure client-side rendering if needed

// import React, { useState, useEffect } from 'react';
// import { PlusIcon, MinusIcon } from 'lucide-react';
// import style from '@/assets/styling/scss/documentation.module.scss';

// interface NavItem {
//   type: 'file' | 'directory';
//   name: string;
//   path?: string;
//   content?: string;
//   children?: NavItem[];
// }

// interface SideNavProps {
//   navItems: NavItem[];
//   onFileSelect: (path: string) => void;
//   activePath: string;
// }

// const SideNav: React.FC<SideNavProps> = ({ navItems, onFileSelect, activePath }) => {
//   const [expandedDirs, setExpandedDirs] = useState<Set<string>>(new Set());

//   useEffect(() => {
//     // Open only the top-level directories initially
//     const topLevelDirs = navItems
//       .filter(item => item.type === 'directory' && item.path)
//       .map(item => item.path || '');
//     setExpandedDirs(new Set(topLevelDirs));
//   }, [navItems]);

//   const toggleDirectory = (path: string) => {
//     setExpandedDirs(prev => {
//       const newExpandedDirs = new Set(prev);
//       if (newExpandedDirs.has(path)) {
//         newExpandedDirs.delete(path);
//       } else {
//         newExpandedDirs.add(path);
//       }
//       return newExpandedDirs;
//     });
//   };

//   const renderNav = (items: NavItem[], parentPath: string = ''): JSX.Element => {
//     return (
//       <ul className={style.list}>
//         {items.map((item, index) => {
//           const itemPath = parentPath ? `${parentPath}/${item.path || ''}` : item.path || '';
//           const isActive = item.path === activePath;
//           const isExpanded = expandedDirs.has(itemPath);

//           return (
//             <li key={itemPath || index} className={style.listItem}>
//               {item.type === 'directory' ? (
//                 <>
//                   <div className={style.directoryHeader} onClick={() => toggleDirectory(itemPath)}>
//                     {isExpanded ? <MinusIcon size={12} /> : <PlusIcon size={12} />}
//                     <span>{item.name}</span>
//                   </div>
//                   {isExpanded && item.children && renderNav(item.children, itemPath)}
//                 </>
//               ) : (
//                 <div
//                   onClick={() => onFileSelect(item.name || '')}
//                   className={isActive ? style.activeFile : style.file}
//                 >
//                   {item.name}
//                 </div>
//               )}
//             </li>
//           );
//         })}
//       </ul>
//     );
//   };

//   return <nav>{renderNav(navItems)}</nav>;
// };

// export default SideNav;

"use client"; // Ensure client-side rendering if needed

import React, { useState, useEffect } from 'react';
import { PlusIcon, MinusIcon } from 'lucide-react';
import style from '@/assets/styling/scss/documentation.module.scss';

interface NavItem {
  type: 'file' | 'directory';
  name: string;
  path?: string;
  content?: string;
  children?: NavItem[];
}

interface SideNavProps {
  navItems: NavItem[];
  onFileSelect: (path: string) => void;
  activePath: string;
}

const SideNav: React.FC<SideNavProps> = ({ navItems, onFileSelect, activePath }) => {
  const [expandedDirs, setExpandedDirs] = useState<Set<string>>(new Set());

  useEffect(() => {
    // Open only the top-level directories initially
    const topLevelDirs = navItems
      .filter(item => item.type === 'directory' && item.path)
      .map(item => item.path || '');
    setExpandedDirs(new Set(topLevelDirs));
  }, [navItems]);

  const toggleDirectory = (path: string) => {
    setExpandedDirs(prev => {
      const newExpandedDirs = new Set(prev);
      if (newExpandedDirs.has(path)) {
        newExpandedDirs.delete(path);
      } else {
        newExpandedDirs.add(path);
      }
      return newExpandedDirs;
    });
  };

  const renderNav = (items: NavItem[], parentPath: string = ''): JSX.Element => {
    return (
      <ul className={style.list}>
        {items.map((item, index) => {
          const itemPath = parentPath ? `${parentPath}/${item.path || ''}` : item.path || '';
          const isActive = item.path === activePath;
          const isExpanded = expandedDirs.has(itemPath);

          return (
            <li key={index} className={style.listItem}>
              {item.type === 'directory' ? (
                <>
                  <div className={style.directoryHeader} onClick={() => toggleDirectory(itemPath)}>
                    {isExpanded ? <MinusIcon size={12} /> : <PlusIcon size={12} />}
                    <span>{item.name}</span>
                  </div>
                  {isExpanded && item.children && renderNav(item.children, itemPath)}
                </>
              ) : (
                <div
                  onClick={() => onFileSelect(item.path || '')}
                  className={isActive ? style.activeFile : style.file}
                >
                  {item.name}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  return <nav>{renderNav(navItems)}</nav>;
};

export default SideNav;

