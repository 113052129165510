import style from "@/assets/styling/scss/input.module.scss";
import React, { FC } from "react";
import { FormattedMessage } from "@/components/elements";
import { translate } from "@/assets/utils/utils";
import { globalStore } from "@/app/stores";

interface InputProps {
  name: string;
  labelId: string;
  placeholderId: string;
  error?: string;
  type?: string;
}

const Input: FC<InputProps> = ({
  name,
  labelId,
  placeholderId,
  error,
  type = "text",
}) => {
  const { lang } = globalStore;

  return (
    <div className={style.inputWrapper}>
      <label className={style.label}>
        <FormattedMessage id={labelId} />
      </label>

      <div className={style.inputContainer}>
        <input
          {...name}
          className={style.input}
          type={type}
          placeholder={translate(lang, placeholderId)}
        />
      </div>

      {error && <p className={style.error}>{translate(lang, error)}</p>}
    </div>
  );
};

export default Input;
