"use client";
import Image from 'next/image';
import style from '@/assets/styling/scss/logo.module.scss';

interface LogoProps {
  width?: number;
  styling?: React.CSSProperties; 
}

const Logo: React.FC<LogoProps> = ({ width = 110, styling }) => {
  const aspectRatio = 110 / 38; 
  const height = Math.round(width / aspectRatio);

  function handleClick() {
    window.location.href = "/";
  }

  return (
    <>
      <h1 className={style.hidden}>BlueCherry - Connect. Anything. Anywhere.</h1>
      <Image
        src="/images/logo_cherry_bluecherry.svg"
        width={width}
        height={height}
        style={{ width: `${width}px`, ...styling }}
        alt="BlueCherry Logo"
        className={style.logo}
        onClick={handleClick}
      />
    </>
  );
}

export default Logo;
