"use client"
import { NavBar, Footer } from '@/components/partials';
import { FormattedMessage, GoBack } from '../elements';
import { motion } from 'framer-motion';
import { paperVariants } from '@/assets/utils/motion';
import style from '@/assets/styling/scss/vouchers.module.scss';
import { useRef } from 'react';

export default function AskAVoucher() {
  const voucherRefs = useRef([]);

  const handleMouseMove = (e, index) => {
    const { clientX, clientY, currentTarget } = e;
    const { offsetWidth, offsetHeight } = currentTarget;
    const x = (clientX - currentTarget.getBoundingClientRect().left) / offsetWidth;
    const y = (clientY - currentTarget.getBoundingClientRect().top) / offsetHeight;
    const rotateX = (y - 0.5) * 30;
    const rotateY = (x - 0.5) * -30;
    const transform = `rotateY(${rotateY}deg) rotateX(${rotateX}deg)`;
    currentTarget.style.transform = transform;
  };

  const handleMouseLeave = (index) => {
    if (voucherRefs.current[index]) {
      voucherRefs.current[index].style.transform = 'rotateY(0deg) rotateX(0deg)';
    }
  };

  return (
    <>
      <NavBar />
      <main>
        <motion.section
          className={style.content}
          variants={paperVariants}
          initial="hidden"
          animate={["visible", "scale", "translateY"]}
          exit="exit"
        >
          <GoBack />
          <h1>
            <FormattedMessage id="subtitle_voucher" />
          </h1>
          <h2>
            <FormattedMessage id="vouchers_subdescription" />
          </h2>
          <p className={`${style.text} leadText`}>
            <FormattedMessage id="vouchers_suub_description" />
            <span>
              &nbsp; <FormattedMessage id="vouchers_suub_description_span" />
            </span>
          </p>
          <section className={style.voucherWrapper}>
            {['40 SMS Messages', 'Free Emailing', '100 Phone Calls', '1000 app notifications', 'BlueCherry subscription', '5 additional co-users'].map((voucher, index) => (
              <div
                className={style.voucher}
                key={index}
                ref={el => voucherRefs.current[index] = el}
                onMouseMove={(e) => handleMouseMove(e, index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <h1 className={style.voucherTitle}>{voucher}</h1>
                <span className={style.voucherType}>{index === 0 ? "SMS bundle" : index === 1 ? "Email bundle" : index === 2 ? "Phone call bundle" : index === 3 ? "Push notification bundle" : index === 4 ? '1 year subscription' : 'User addon'}</span>
              </div>
            ))}
          </section>
        </motion.section>
      </main>
      <Footer />
    </>
  );
}
