import style from '@/assets/styling/scss/homepage.module.scss';
import { FormattedMessage } from '@/components/elements';


const ComparisonTable = () => {

  return (
<table className={style.table} summary="This table compares key features and criteria for various IoT platforms.">
  <thead>
    <tr>
      <th scope="row"></th>
      <th scope="col">BlueCherry IoT</th>
      <th scope="col">Microsoft Azure IoT</th>
      <th scope="col">Amazon AWS IoT</th>
      <th scope="col">Google Cloud IoT Core</th>
    </tr>
  </thead>
  <tbody>
    <tr>
    <th scope="row"><FormattedMessage id="device_control" /></th>
    <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_device_control" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_device_control" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_device_control" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_device_control" /></p></td>
    </tr>
    <tr>
    <th scope="row"><FormattedMessage id="device_management" /></th>
    <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_device_management" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_device_management" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_device_management" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_device_management" /></p></td>
    </tr>
    <tr>
      <th scope="row"><FormattedMessage id="device_type_support" /></th>
      <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_device_type_support" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_device_type_support" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_device_type_support" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_device_type_support" /></p></td>
    </tr>
    <tr>
      <th scope="row"><FormattedMessage id="protocol_support" /></th>
      <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_protocol_support" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_protocol_support" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_protocol_support" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_protocol_support" /></p></td>
    </tr>
    <tr>
      <th scope="row"><FormattedMessage id="pricing_model" /></th>
      <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_pricing_model" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_pricing_model" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_pricing_model" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_pricing_model" /></p></td>
    </tr>
    <tr>
      <th scope="row"><FormattedMessage id="notification_channels" /></th>
      <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_notification_channels" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_notification_channels" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_notification_channels" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_notification_channels" /></p></td>
    </tr>
    <tr>
      <th scope="row"><FormattedMessage id="long_term_availability" /></th>
      <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_long_term_availability" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_long_term_availability" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_long_term_availability" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_long_term_availability" /></p></td>
    </tr>
    <tr>
      <th scope="row"><FormattedMessage id="scalability" /></th>
      <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_scalability" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_scalability" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_scalability" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_scalability" /></p></td>
    </tr>
    <tr>
      <th scope="row"><FormattedMessage id="development_support" /></th>
      <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_development_support" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_development_support" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_development_support" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_development_support" /></p></td>
    </tr>
    <tr>
      <th scope="row"><FormattedMessage id="compatibility_with_other_cloud_platforms" /></th>
      <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_compatibility_with_other_cloud_platforms" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_compatibility_with_other_cloud_platforms" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_compatibility_with_other_cloud_platforms" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_compatibility_with_other_cloud_platforms" /></p></td>
    </tr>
    <tr>
      <th scope="row"><FormattedMessage id="direct_support_for_5g_lpwa_devices" /></th>
      <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_direct_support_for_5g_lpwa_devices" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_direct_support_for_5g_lpwa_devices" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_direct_support_for_5g_lpwa_devices" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_direct_support_for_5g_lpwa_devices" /></p></td>
    </tr>
    <tr>
      <th scope="row"><FormattedMessage id="supported_data_formats" /></th>
      <td data-header="BlueCherry"><p><FormattedMessage id="bluecherry_supported_data_formats" /></p></td>
      <td data-header="Microsoft Azure IoT"><p><FormattedMessage id="azure_iot_supported_data_formats" /></p></td>
      <td data-header="Amazon AWS IoT"><p><FormattedMessage id="aws_iot_supported_data_formats" /></p></td>
      <td data-header="Google Cloud IoT Core"><p><FormattedMessage id="google_cloud_iot_core_supported_data_formats" /></p></td>
    </tr>
  </tbody>
</table>

  )
}

export default ComparisonTable;
