"use client";
import style from "@/assets/styling/scss/hamburger.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import NextLink from "next/link";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import { LanguageToggle, FormattedMessage } from "@/components/elements";

interface CustomLinkProps {
  link: string;
  linkName: string;
}

const CustomLink: FC<CustomLinkProps> = ({
  link,
  linkName,
  toggleBurger,
  isExternal,
}) => {
  const handleClick = (e) => {
    if (!isExternal) {
      e.preventDefault();
      toggleBurger();
      setTimeout(() => (window.location.href = link), 600);
    }
  };

  return (
    <li className={style.listItem}>
      <NextLink
        href={link}
        passHref
        onClick={handleClick}
        target={isExternal ? "_blank" : undefined}
        rel={isExternal ? "noopener noreferrer" : undefined}
      >
        {linkName}
      </NextLink>
    </li>
  );
};

const HamburgerContent = ({ isOpen, toggleBurger }) => {
  // this locks this component
  useLockBodyScroll();

  return (
    <motion.div
      className={`${style.hamburgerMenu} ${isOpen ? "" : style.hidden}`}
      initial={{ x: "150vw" }}
      animate={{ x: "50vw" }}
      exit={{ x: "150vw" }}
      transition={{
        duration: 0.6,
        ease: [0.25, 1, 0.5, 1],
        delay: 0.2,
      }}
    >
      <ul className={style.list}>
        <p className={style.listTitle}>For Companies</p>
        <CustomLink
          link="/for-companies/success-stories"
          linkName="Success Stories"
          toggleBurger={toggleBurger}
        />
        <CustomLink
          link="/for-companies/submit-a-request"
          linkName="Submit your project"
          toggleBurger={toggleBurger}
        />
        <CustomLink
          link="/for-companies/pricing"
          linkName="Pricing"
          toggleBurger={toggleBurger}
        />

        <CustomLink
          link="/for-companies/vouchers"
          linkName="Vouchers"
          toggleBurger={toggleBurger}
        />
      </ul>

      <ul className={style.list}>
        <p className={style.listTitle}>For Developers</p>
        <CustomLink
          link="/for-developers/bluecherry"
          linkName="Bluecherry"
          toggleBurger={toggleBurger}
        />
        <CustomLink
          link="/for-developers/documentation"
          linkName="Documentation"
          toggleBurger={toggleBurger}
        />
        <CustomLink
          link="/for-developers/download-bluecherry-client"
          linkName="Download Bluecherry Client"
          toggleBurger={toggleBurger}
        />

        <CustomLink
          link="https://www.discord.com"
          linkName="Discord"
          toggleBurger={toggleBurger}
          isExternal={true}
        />
      </ul>

      <ul className={style.list}>
        <LanguageToggle />

        <a
          href="https://sso.bluecherry.io/"
          target="_blank"
          rel="noopener noreferrer"
          className={style.loginLink}
        >
          <FormattedMessage id="login_link_bluecherry" />
        </a>
      </ul>
    </motion.div>
  );
};

export default function Hamburger({ isOpen, toggleBurger }) {
  return (
    <div className={style.hamburgerWrapper}>
      <div className={`${style.hamburger} ${isOpen ? style.open : ""}`} onClick={toggleBurger}></div>

      <AnimatePresence>
        {isOpen && (
          <HamburgerContent isOpen={isOpen} toggleBurger={toggleBurger} />
        )}
      </AnimatePresence>
    </div>
  );
}
