import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkSlug from 'remark-slug';
import remarkAutolinkHeadings from 'remark-autolink-headings';
import style from '@/assets/styling/scss/markdown.module.scss';

interface MarkdownProps {
  content: string;
}

const Markdown: React.FC<MarkdownProps> = ({ content }) => {
  if (!content || typeof content !== 'string') {
    console.warn('Markdown content is empty or invalid:', content);
    return <div className={style.markdownContent}><p>No content available.</p></div>;
  }

  return (
    <div className={style.markdownContent}>
      <ReactMarkdown
        remarkPlugins={[
          remarkSlug,
          [remarkAutolinkHeadings, { behavior: 'append' }]
        ]}
        components={{
          a: ({ node, ...props }) => <a {...props} />
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default Markdown;

