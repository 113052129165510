import { FC } from 'react';
import NextLink from 'next/link';
import { Logo, LanguageToggle, FormattedMessage } from '@/components/elements';
import style from '@/assets/styling/scss/footer.module.scss';
import Image from 'next/image';

interface CustomLinkProps {
  link: string;
  linkName: string;
}

const CustomLink: FC<CustomLinkProps> = ({ link, linkName }) => {
  return (
    <li className={style.listItem}>
      <NextLink href={link}><FormattedMessage id={linkName} /></NextLink>
    </li>
  );
};

const Footer: FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={style.footer}>
      <div className={style.container}>
        <div className={style.listWrapper}>
          <Logo width={150} styling={{ zIndex: "-1" }} />

          <div className={style.bluecherry}>
            <h1
              className={style.bluecherryTitle}
              style={{ marginBottom: ".5rem" }}
            >
              Connect. Anything. Anywhere.
            </h1>
            <p className={style.bluecherryText}>
              <FormattedMessage id="bluecherry_power" />
              <span>
                <FormattedMessage id="bluecherry_power_span" />
              </span>
            </p>
          </div>

          <ul className={style.list}>
            <p className={style.listTitle}>
              <FormattedMessage id="for_companies" />
            </p>
            <CustomLink
              link="/for-companies/success-stories"
              linkName="success_stories"
            />
            <CustomLink
              link="/for-companies/submit-a-request"
              linkName="submit_your_project"
            />
            <CustomLink 
              link="/for-companies/pricing"
              linkName="pricing" 
             />
            <CustomLink
              link="/for-companies/vouchers"
              linkName="vouchers"
            />
            {/* <CustomLink
              link="/for-companies/become-a-partner"
              linkName="become_a_partner"
            /> */}
          </ul>

          <ul className={style.list}>
            <p className={style.listTitle}>
              <FormattedMessage id="for_developers" />
            </p>
            <CustomLink
              link="/for-developers/bluecherry"
              linkName="bluecherry"
            />
            <CustomLink
              link="/for-developers/documentation"
              linkName="documentation"
            />
            <CustomLink
              link="/for-developers/download-bluecherry-client"
              linkName="download_bluecherry_client"
            />
          </ul>

          <ul className={style.list}>
            <p className={style.listTitle}>
              <FormattedMessage id="contact_us" />
            </p>
            <li className={style.listItem}>
              <NextLink href="mailto:info@bluecherry.io">
                info@bluecherry.io
              </NextLink>
            </li>
            <li className={style.listItem}>
              <NextLink href="tel:050622888">+32 (0) 50 62 28 88</NextLink>
            </li>
            <li className={style.listItem}>
              <a>
                Westkappellestraat 396/44 <br />
                8300 Knokke-Heist
              </a>
            </li>
            <li className={style.listItem}>
              <a>VAT BE0429 557 768</a>
            </li>
          </ul>
        </div>
        <div className={style.sponsorWrapper}>
          <Image
            className={style.flanders_lion_image}
            src="/images/flanders_art.png"
            width="496"
            height="204"
            alt="Flanders Investment & Trade"
          />
        </div>
        <div className={style.slab}>
          <p className={style.copyright}>
            &copy;
            <FormattedMessage
              id="copyright_text"
              replacements={{ year: currentYear }}
            />
            &nbsp;-&nbsp;
            <a href="/legal/terms-of-conditions">
              <FormattedMessage id="terms_of_conditions" />
            </a>
            &nbsp;-&nbsp;
            <a href="/legal/privacy-policy">
              <FormattedMessage id="privacy_policy" />
            </a>
          </p>
          <LanguageToggle />
        </div>
      </div>
    </footer>
  );
};

export default Footer;


