
export const formatEmailContent = (formData) => {
  const { 
      company_name, 
      contact_person, 
      email, 
      project_description, 
      project_name, 
      phone, 
      resources, 
      budget, 
      quantity, 
      install_software, 
      operating_system, 
      market_potential, 
      reason_for_switching, 
      current_platform, 
      other_reason 
  } = formData;

  const subject = `Bluecherry Project | ${company_name || ''} - ${contact_person || ''}`.trim();

  const textContentArray = [];
  const htmlContentArray = [];

  const addField = (label, value) => {
      if (value !== undefined && value !== null && value !== '') {
          textContentArray.push(`${label}: ${value}`);
          htmlContentArray.push(`<p><strong>${label}:</strong> ${value}</p>`);
      }
  };

  addField('Company Name', company_name);
  addField('Contact Person', contact_person);
  addField('Email', email);
  addField('Phone', phone);
  addField('Project Name', project_name);
  addField('Project Description', project_description);
  addField('Resources', resources);
  addField('Budget', budget);
  addField('Quantity', quantity);
  addField('Install Software', install_software);
  addField('Operating System', operating_system);
  addField('Market Potential', market_potential);
  addField('Reason for Switching', reason_for_switching);
  addField('Current Platform', current_platform);
  addField('Other Reason', other_reason);

  const textContent = textContentArray.join('\n');
  const htmlContent = htmlContentArray.join('');

  return { subject, textContent, htmlContent };
}