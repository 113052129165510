"use client";
import style from '@/assets/styling/scss/homepage.module.scss';
import { Button } from '@/components/elements';
import { ArrowRight, MoveDown } from 'lucide-react';
import { FormattedMessage, VideoPlayer } from '@/components/elements';
import Link from 'next/link';

const Header = () => {
  
  return (
    <header className={style.hero}>
      <VideoPlayer />

      <h1 className={style.heroTitle}>
        Connect. Anything. Anywhere.
      </h1>
      <p className={`${style.heroIntroduction} leadText`}>
        <FormattedMessage id="hero_introduction" />
      </p>

      <div className={style.heroButtons}>
        <Button text="read_stories" link="/for-companies/success-stories" />
        <div>
          <Button text="start_your_project" color="var(--accent-secondary)" link="/for-companies/submit-a-request" icon={<ArrowRight />} />
          <p className={style.sideInfo}>
            <FormattedMessage id="side_info" />
          </p>
        </div>
      </div>

      <Link href="#leadArticle">
        <MoveDown className={style.moveDown} />
      </Link>
    </header>
  );
};

export default Header;
