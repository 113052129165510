"use client"

import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { globalStore } from '@/app/stores';
import { translate } from '@/assets/utils/utils';

type IProps = {
  id: string;
  replacements?: { [key: string]: string };
}

const FormattedMessage = observer(({ id, replacements }: IProps) => {
  const { lang } = globalStore;
  const [translatedValue, setTranslatedValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    setTranslatedValue(translate(lang, id, replacements));
  }, [lang, id, replacements]);

  if (translatedValue === undefined) {
    return null; 
  }

  return <>{translatedValue}</>;
});

export default FormattedMessage;
