import { FC } from "react";
import style from "@/assets/styling/scss/explainer.module.scss";
import { FormattedMessage } from "@/components/elements";

interface ExplainerProps {
  iconPath: string;
  subtitle: string;
  title: string;
  text: string;
}

const Explainer: FC<ExplainerProps> = ({ iconPath, subtitle, title, text }) => (
  <div>
    <div className={style.logoWrapper}>
      <div className={style.icon} style={{ maskImage: `url(${iconPath})` }} />
      <div>
        <h3 className={style.subtitle}>
          <FormattedMessage id={subtitle} />
        </h3>
        <h2 className={style.title}>
        <FormattedMessage id={title} />
        </h2>
      </div>
    </div>
    <p className={style.text}>
      <FormattedMessage id={text} />
    </p>
  </div>
);

export default Explainer;
