"use client";

import React, { useState, useEffect } from 'react';
import { NavBar, Footer } from '../partials';
import { SideNav, MarkDown, Logo } from '../elements';
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { parseDocs, translate } from '@/assets/utils/utils';
import style from '@/assets/styling/scss/documentation.module.scss';
import { globalStore, docStore } from "@/app/stores";
import { observer } from 'mobx-react';

interface NavItem {
  type: 'file' | 'directory';
  name: string;
  path?: string;
  content?: string;
  children?: NavItem[];
}

const Documentation = observer(() => {
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const [filteredNavItems, setFilteredNavItems] = useState<NavItem[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [activePath, setActivePath] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState('Search in Documentation');

  const translaterTitle = (key: string) => {
    if (typeof window === 'undefined') {
      return key === 'search_placeholder' ? 'Search in Documentation' : key;
    }
    return translate(globalStore.lang, key);
  };

  useEffect(() => {
    setPlaceholder(translaterTitle('search_placeholder'));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 400);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    async function fetchDocs() {
      try {
        const response = await fetch('/api/doctree?populate=*');

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const parsedNavItems = parseDocs(data);

        setNavItems(parsedNavItems);
        setFilteredNavItems(parsedNavItems);

        const firstFile = findFirstFile(parsedNavItems);

        if (firstFile && !docStore.activeFile) {
          setActivePath(firstFile.path || '');
          setSelectedFile(firstFile.content || '');
        }
      } catch (error) {
        console.error('Failed to fetch documentation:', error);
      }
    }

    fetchDocs();
  }, []);

  useEffect(() => {
    if (docStore.selectedFile) {
      setSelectedFile(docStore.selectedFile || '');
      setActivePath(docStore.activePath || '');
    } else {
      const firstFile = findFirstFile(navItems);
      if (firstFile) {
        setActivePath(firstFile.path || '');
        setSelectedFile(firstFile.content || '');
      }
    }
  }, [docStore.selectedFile, docStore.activePath, navItems]);

  useEffect(() => {
    const filterNavItems = (query: string) => {
      if (!query) {
        setFilteredNavItems(navItems);
        return;
      }

      const search = (items: NavItem[]): NavItem[] => {
        return items.reduce((acc: NavItem[], item: NavItem) => {
          const matchesTitle = item.name.toLowerCase().includes(query.toLowerCase());
          const matchesContent = item.content?.toLowerCase().includes(query.toLowerCase()) || false;

          if (item.type === 'file' && (matchesTitle || matchesContent)) {
            acc.push(item);
          } else if (item.type === 'directory' && item.children) {
            const filteredChildren = search(item.children);
            if (filteredChildren.length > 0 || matchesTitle) {
              acc.push({ ...item, children: filteredChildren });
            }
          }
          return acc;
        }, []);
      };

      const filteredItems = search(navItems);
      setFilteredNavItems(filteredItems);
    };

    filterNavItems(searchQuery);
  }, [searchQuery, navItems]);

  const findFirstFile = (items: NavItem[]): NavItem | null => {
    for (const item of items) {
      if (item.type === 'file') {
        return item;
      }
    }

    for (const item of items) {
      if (item.type === 'directory' && item.children) {
        const result = findFirstFile(item.children);
        if (result) return result;
      }
    }

    return null;
  };

  const handleFileSelect = (path: string) => {
    const file = findFileByPath(filteredNavItems, path);
    if (file) {
      docStore.activeFile = file;
      setActivePath(path);
      setSelectedFile(file.content || '');
    }
  };

  const findFileByPath = (items: NavItem[], path: string): NavItem | null => {
    for (const item of items) {
      if (item.path === path) {
        return item;
      }
      if (item.children) {
        const result = findFileByPath(item.children, path);
        if (result) return result;
      }
    }
    return null;
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <NavBar />
      <main className={style.mainDocumentation}>
        {!isMobile && (
          <section className={style.sidebar}>
            <div className={style.topLogo}>
              <Logo width={160} />
            </div>

            <div className={style.searchWrapper}>
              <input
                type="text"
                placeholder={placeholder}
                className={style.search}
                onChange={handleSearchChange}
              />
              <MagnifyingGlassIcon className={style.searchIcon} />
            </div>

            <SideNav
              navItems={filteredNavItems}
              onFileSelect={handleFileSelect}
              activePath={activePath}
            />
          </section>
        )}
        <section className={style.content}>
          {isMobile ? (
            <p>Documentation cannot be read on mobile.</p>
          ) : (
            selectedFile ? <MarkDown content={selectedFile} /> : <p>Select a file to view its content.</p>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
});

export default Documentation;
