export const paperVariants = {
    hidden: {
      translateY: -100,
      scale: 1.2,
      rotate: -1,
      opacity: 0,
      x: -5,
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
    },
    visible: {
      opacity: 1,
      boxShadow: '0 0px 0px rgba(0, 0, 0, 0)',
      x: 0,
      transition: {
        duration: 1.2,
        ease: [0.5, 0.05, 0.1, 0.3],
      },
    },
    scale: {
      scale: 1,
      rotate: 0,
      transition: {
        duration: 0.6,
        ease: [0.6, 0.2, 0.1, 0.8],
      },
    },
    translateY: {
      translateY: 0,
      transition: {
        duration: 0.6,
        ease: "easeInOut",
        delay: 0.2,
      },
    },
    exit: {
      translateY: 20,
      scale: 0.95,
      rotate: 2,
      opacity: 0,
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
      transition: {
        duration: 0.6,
        ease: [0.4, 0, 0.2, 1],
      },
    },
  };