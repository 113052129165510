export const doFetch = async (url: string, method: string = 'GET', data?: any, onFailData = []): Promise<{ status: number, data: any }> => {
  try {
    const options: RequestInit = {
      method,
      mode: 'same-origin',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: method !== 'GET' ? JSON.stringify(data) : undefined,
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const parsedData = await response.json();

    return {
      status: response.status,
      data: parsedData,
    };
  } catch (error) {
    console.error('Fetch error:', error);
    return {
      status: error.status || 500,
      data: [],
    };
  }
};

export function processParagraph(paragraph) {
  return paragraph.map(item => {
    if (item.type === 'paragraph') {
      return item.children.join(' '); 
    }
    return '';
  }).join('\n'); 
}
