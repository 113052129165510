"use client";
import { Footer, NavBar } from "../partials";
import { FormattedMessage, GoBack } from "@/components/elements";
import { SubmitARequestForm } from "@/components/forms";
import { doFetch } from "@/app/utils";
import { useEffect, useState } from "react";
import style from "@/assets/styling/scss/submitarequest.module.scss";
import {motion} from 'framer-motion';
import { paperVariants } from "@/assets/utils/motion";

async function getPartners() {
  const { data: { data } } = await doFetch(`/api/partners?populate=*`, "GET");

  return data.map(({ id, attributes }) => ({
    id,
    partner: {
      name: attributes.partner_name,
      region: attributes.region,
      description: attributes.description,
    },
  }));
}

export default function SubmitRequest() {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    async function fetchPartners() {
      const partners = await getPartners();
      setPartners(partners);
    }

    fetchPartners();
  }, []);

  return (
    <>
      <NavBar />
      <main>
        <motion.section
          className={style.content}
          variants={paperVariants}
          initial="hidden"
          animate={["visible", "scale", "translateY"]}
          exit="exit"
        >
          <GoBack />

          <h1 className={style.title}>
            <FormattedMessage id="start_your_project" />
          </h1>
          <h2 className={style.subtitle}>
            <FormattedMessage id="project_title" />
          </h2>
          <p className={`${style.text} leadText`}>
            <FormattedMessage id="project_intro" />
            <span>
              &nbsp; <FormattedMessage id="project_span" />
            </span>
          </p>

          <SubmitARequestForm partners={partners} />
        </motion.section>
      </main>
      <Footer />
    </>
  );
}
