// import { promises as fs } from 'fs';
import path from 'path';
// import matter from 'gray-matter';

const docsDirectory = path.join(process.cwd(), 'public/docs');

export const test = () => {
  return {
    'test': 'test'
  }
}

export const getAllDocs = async () => {
  try {
    const fileNames = await fs.readdir(docsDirectory, { withFileTypes: true });
    const docs = [];

    for (const fileOrDir of fileNames) {
      const fullPath = path.join(docsDirectory, fileOrDir.name);

      if (fileOrDir.isDirectory()) {
        const subDirFiles = await fs.readdir(fullPath);
        for (const subFile of subDirFiles) {
          const subFilePath = path.join(fullPath, subFile);
          const fileContents = await fs.readFile(subFilePath, 'utf8');
          const { data, content } = matter(fileContents);
          docs.push({
            path: `${fileOrDir.name}/${subFile.replace(/\.md$/, '')}`,
            content,
            ...data,
          });
        }
      } else {
        const fileContents = await fs.readFile(fullPath, 'utf8');
        const { data, content } = matter(fileContents);
        docs.push({
          path: fileOrDir.name.replace(/\.md$/, ''),
          content,
          ...data,
        });
      }
    }

    return docs;
  } catch (error) {
    console.error('Error reading docs:', error);
    return []; // Return an empty array if there's an error
  }
}
































































































































































































































































































































































































