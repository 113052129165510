import Link from 'next/link';
import { getAllDocs } from '@/assets/utils/markdown';

const SideBar = () => {
  const docs = getAllDocs();

  return (
    <nav>
      <ul>
        {docs.map((doc) => (
          <li key={doc.path}>
            <Link href={`/documentation/${doc.path}`}>
              {doc.path.replace(/-/g, ' ')}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default SideBar;
