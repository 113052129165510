import { FC } from 'react';
import style from '@/assets/styling/scss/bluecherry.module.scss';
import Image from 'next/image';
import { FormattedMessage } from '@/components/elements';

interface FocusPointProps {
  iconPath: string;
  alt: string;
  text: string;
}

const FocusPoint: FC<FocusPointProps> = ({ iconPath, alt, text }) => (
  <div className={style.focusPoint}>
    <Image src={iconPath} width="50" height="50" alt={alt} />
    <p><FormattedMessage id={text} /></p>
  </div>
)

export default FocusPoint;
