// stores/GlobalStore.ts
import { doFetch } from "@/assets/utils/utils";
import { formatEmailContent } from '@/assets/utils/formatEmailContent';
import { observe, makeObservable, observable, action, computed } from "mobx";

interface Language {
  short: string;
  long: string;
}

interface EmailPayload {
  to: string;
  subject: string;
  text: string;
  html: string;
}

class GlobalStore {
  crumbName: string;
  lang: string;
  languages: Language[];
  preferredLanguage: string;
  navOn: boolean;

  constructor() {
    this.languages = [
      { short: "nl", long: "Nederlands" },
      { short: "fr", long: "Français" },
      { short: "de", long: "Deutsch" },
      { short: "en", long: "English" },
      { short: "es", long: "Español" },
      { short: "it", long: "Italiano" },
    ];

    this.navOn = true;
    this.preferredLanguage = "en";
    this.crumbName = "";
    this.lang = this.getLang(); 

    makeObservable(this, {
      navOn: observable,
      crumbName: observable,
      lang: observable,
      preferredLanguage: observable,
      setCrumb: action,
      setLang: action,
      setNav: action,
      getLang: action,
      getCrumb: computed,
      getFullLang: action,
      sendMail: action,
    });

    observe(this, "lang", () => {
      this.saveLang();
    });

    observe(this, "preferredLanguage", () => {
      this.lang = this.preferredLanguage;
      this.saveLang();
    });
  }

  setNav = (value) => {
    this.navOn = value;
  }

  setCrumb = (name: string): void => {
    this.crumbName = name;
  };

  get getCrumb(): string {
    return this.crumbName;
  }

  setLang = (newLang: string): void => {
    if (this.languages.some((language) => language.short === newLang)) {
      this.lang = newLang;
      this.saveLang();
    } else {
      console.error(`Language ${newLang} is not supported.`);
    }
  };

  getLang = (): string => {
    if (typeof window !== "undefined") {
      const siteLang = this.getCookie("site_lang");
      if (
        siteLang &&
        this.languages.some((language) => language.short === siteLang)
      ) {
        return siteLang;
      }

      const browserLang = navigator.language || navigator.languages[0];
      const shortBrowserLang = browserLang.split("-")[0];

      if (
        this.languages.some((language) => language.short === shortBrowserLang)
      ) {
        return shortBrowserLang;
      }

      return this.preferredLanguage;
    }
    return this.preferredLanguage;
  };

  getFullLang = (): string => {
    const language = this.languages.find((lang) => lang.short === this.lang);
    return `${language?.long} - (${language?.short.toUpperCase()})`;
  };

  saveLang = (): void => {
    if (typeof window !== "undefined") {
      this.setCookie("site_lang", this.lang, 365);
    }
  };

  setCookie(name: string, value: string, days: number): void {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value};${expires};path=/`;
  }

  getCookie(name: string): string | null {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  sendMail = async (formData: any): Promise<void> => {
    const { subject, textContent, htmlContent } = formatEmailContent(formData);
  
    const emailPayload: EmailPayload = {
      to: "victor_byttebier@outlook.com",
      subject: subject,
      text: textContent,
      html: htmlContent,
    };

    console.log(emailPayload);

    const response = await doFetch( `/api/send-email`, "POST", emailPayload );
    console.log(response);
  };
}

export default new GlobalStore();
