"use client";
import { NavBar, Footer } from "../partials";
import { ScrollToTop, Story } from '@/components/elements';
import { doFetch } from '@/app/utils';
import style from '@/assets/styling/scss/stories.module.scss';
import { useEffect, useState } from "react";
import { motion } from 'framer-motion';

const containerVariants = {
  hidden: { opacity: 0.2 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,  
      when: "beforeChildren",
      duration: 0.4,  
    },
  },
};

async function getStoryData() {
  const { data: { data } } = await doFetch('/api/stories?populate=*', 'GET');

  return data.map(({ id, attributes }) => ({
    id,
    information: attributes.information,
    image: attributes.image.data.attributes,
    gallery: attributes.gallery.data,
  }));
}

export default function SuccessStories() {
  const [stories, setStories] = useState([]);

  useEffect(() => {
    async function fetchStories() {
      const storiesData = await getStoryData();
      setStories(storiesData);
    }

    fetchStories();
  }, []);

  if (stories.length === 0) return null; // this line is neccessary to stagger <Story /> animation

  return (
    <>
      <ScrollToTop />
      <NavBar />
      <main style={{ maxWidth: '112rem', margin: '10.5rem auto' }}>
        <motion.section
          className={style.stories}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {stories.map(story => (
            <Story key={story.id} {...story} />
          ))}
        </motion.section>
      </main>
      <Footer />
    </>
  );
}
