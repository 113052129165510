"use client"
import { ArrowLeft } from 'lucide-react';
import { useRouter } from 'next/navigation'
import {FormattedMessage} from '@/components/elements';
import style from '@/assets/styling/scss/goback.module.scss';

export default function GoBack() {
  const router = useRouter()

  return (
    <div className={style.goBack} onClick={() => router.back()}>
      <ArrowLeft />
      <FormattedMessage id="go_back"/>
    </div>
  )
}
