"use client"
import { FC } from "react";
import style from "@/assets/styling/scss/homepage.module.scss";
import { ArrowRight } from "lucide-react";
import { Button, FormattedMessage, ImageCarousel } from "@/components/elements";

interface CardProps {
  title: string;
  description: string;
}

const Card: FC<CardProps> = ({ title, description }) => (
  <article className={style.feature}>
    <p className={style.featureTitle}>
      <FormattedMessage id={title} />
    </p>
    <p className={style.featureText}>
      <FormattedMessage id={description} />
    </p>
  </article>
);

const FeaturesAndBenefits = () => {
  return (
    <section className={style.features}>
      <h2 className={style.subtitle}>Features and benefits</h2>

      <div className={style.cardWrapper}>
        <Card
          title="all_in_one_dashboard"
          description="all_in_one_dashboard_description"
        />
        <Card
          title="cloud_independent_operation"
          description="cloud_independent_operation_description"
        />
        <Card
          title="robust_security"
          description="robust_security_description"
        />
        <Card
          title="self_hosting_capability"
          description="self_hosting_capability_description"
        />
        <Card
          title="comprehensive_communication_suite"
          description="comprehensive_communication_suite_description"
        />
        <Card
          title="scalable_flexible"
          description="scalable_flexible_description"
        />
      </div>

      <div className={style.ctaPlatform}>
        <h2>
          <FormattedMessage id="register_today" />
          <span>
            {" "}
            <FormattedMessage id="ask_voucher" />
          </span>
        </h2>{" "}
        <div className={style.featuresButtons}>
          <Button
            text="register"
            icon={<ArrowRight />}
            link="https://sso.bluecherry.io/register"
          />
          <Button
            text="vouchers"
            color="var(--accent-secondary"
            link="/for-companies/vouchers"
          />
        </div>
      </div>
    </section>
  );
};

const Platform = () => {
  const images = [
    {
      url: '/images/carousel_1.png',
      width: '3840',
      height: '2160',
      alternativeText: 'BlueCherry Dashboard'
    },
    {
      url: '/images/carousel_2.png',
      width: '3840',
      height: '2160',
      alternativeText: 'BlueCherry Connect Iot Device'
    },
    {
      url: '/images/carousel_3.png',
      width: '990',
      height: '2160',
      alternativeText: 'BlueCherry Mobile Dashboard'
    },
    {
      url: '/images/carousel_4.png',
      width: '3840',
      height: '2160',
      alternativeText: 'BlueCherry Webshop'
    },
    {
      url: '/images/carousel_5.png',
      width: '2048',
      height: '2732',
      alternativeText: 'BlueCherry Mobile Devices'
    },
    {
      url: '/images/carousel_5.png',
      width: '3840',
      height: '2160',
      alternativeText: 'BlueCherry Account Details'
    },
  ]

  return (
    <section className={style.platform}>
      <h1>
        <FormattedMessage id="our_platform" />
      </h1>
      <h2>
        <FormattedMessage id="cutting_edge_applications" />
      </h2>
      <figure>
        <ImageCarousel images={images} />

        <figcaption>
          <FormattedMessage id="platform_message_part1" /> &nbsp;
          <span>
            <FormattedMessage id="platform_message_count" replacements={{ count: "1 000 000" }} />
          </span>
        </figcaption>
      </figure>

      <FeaturesAndBenefits />
    </section>
  );
};

export default Platform;
