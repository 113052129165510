import { translate, getCookie } from "@/assets/utils/utils";

// Define the function to get the translated message based on the current language
const getMessage = (key) => {
  const lang = getCookie("site_lang") || "en";
  return translate(lang, key);
};

// Define the options with localized labels
export const budgetOptions = [
  { value: "5k - 20k", label: getMessage("budget_5k_20k") },
  { value: "20k - 50k", label: getMessage("budget_20k_50k") },
  { value: "> 50 000", label: getMessage("budget_more_than_50k") },
  { value: "I don't know", label: getMessage("budget_i_dont_know") },
];

export const projectTypeOptions = [
  { value: "design_phase", label: getMessage("project_type_design_phase") },
  { value: "switching_platform", label: getMessage("project_type_switching_platform") },
  { value: "existing_without_sensors", label: getMessage("project_type_existing_without_sensors") },
  { value: "existing_with_computer", label: getMessage("project_type_existing_with_computer") },
];

export const quantityOptions = [
  { value: "0-1000", label: getMessage("quantity_0_1000") },
  { value: "1000-50000", label: getMessage("quantity_1000_50000") },
  { value: "more_than_50000", label: getMessage("quantity_more_than_50000") },
  { value: "dont_know", label: getMessage("quantity_dont_know") },
];

export const installOptions = [
  { value: "yes", label: getMessage("install_yes") },
  { value: "no", label: getMessage("install_no") },
  { value: "dont_know", label: getMessage("install_dont_know") },
];

export const osOptions = [
  { value: "linux", label: getMessage("os_linux") },
  { value: "windows", label: getMessage("os_windows") },
  { value: "mac", label: getMessage("os_mac") },
  { value: "other", label: getMessage("os_other") },
];

export const marketOptions = [
  { value: "0-1000", label: getMessage("quantity_0_1000") },
  { value: "1000-50000", label: getMessage("quantity_1000_50000") },
  { value: "more_than_50000", label: getMessage("quantity_more_than_50000") },
  { value: "dont_know", label: getMessage("quantity_dont_know") },
];

export const switchingOptions = [
  { value: "technical_issue", label: getMessage("switching_technical_issue") },
  { value: "optimize_costs", label: getMessage("switching_optimize_costs") },
  { value: "other", label: getMessage("switching_other") },
];