"use client";
import { useEffect, useState, FC } from "react";
import { motion } from "framer-motion";
import ReactDOM from "react-dom"; // Import ReactDOM for portals
import style from "@/assets/styling/scss/imagecarousel.module.scss";
import { useLockScroll } from "@/assets/utils/utils";
import { globalStore } from '@/app/stores';

interface Image {
  formats?: {
    medium?: {
      url: string;
    };
  };
  url: string;
  width?: number;
  height?: number;
  alternativeText?: string;
}

interface ImageProps {
  images: Image[];
  initialIndex?: number;
  onToggle?: () => void;
}

const Carousel: FC<ImageProps> = ({ images, initialIndex = 0, onToggle }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex);
  const [direction, setDirection] = useState(0);

  useEffect(() => {
    setCurrentImageIndex(initialIndex);
  }, [initialIndex]);

  function handlePrevious(e?: React.MouseEvent) {
    if (e) e.stopPropagation();
    setDirection(-1);
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  }

  function handleNext(e?: React.MouseEvent) {
    if (e) e.stopPropagation();
    setDirection(1);
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        handlePrevious();
      } else if (e.key === "ArrowRight") {
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [images.length]);

  const currentImage = images[currentImageIndex];
  const imageUrl = currentImage.formats?.medium?.url ? currentImage.formats.medium.url : currentImage.url;

  return (
    <div className={style.carousel}>
      <div className={style.carouselImageWrapper}>
        <div className={style.arrowLeft} onClick={handlePrevious} />

        <motion.div
          className={style.motionWrapper}
          key={currentImageIndex}
          initial={{ x: direction === 1 ? 300 : -300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: direction === 1 ? -300 : 300, opacity: 0 }}
          transition={{
            duration: 0.4,
            ease: [0.25, 1, 0.5, 1],
          }}
        >
          <img
            className={style.carouselImage}
            src={imageUrl}
            width={currentImage?.width || "1920"}
            height={currentImage?.height || "1080"}
            alt={currentImage.alternativeText || "Carousel Image"}
            onClick={onToggle}
          />
        </motion.div>

        <div className={style.arrowRight} onClick={handleNext} />
      </div>
    </div>
  );
};

interface ImageCarouselProps {
  images: Image;
  initialIndex?: number;
  handleImageCarousel?: () => void;
}

const ImageCarousel: FC<ImageCarouselProps> = ({ images, initialIndex, handleImageCarousel }) => {
  const [overlay, setOverlay] = useState(false);
  const shouldShowOverlay = initialIndex !== undefined && initialIndex !== null;

  useLockScroll(overlay);

  function toggleCarousel() {
    if (shouldShowOverlay && handleImageCarousel) {
      handleImageCarousel();
      globalStore.setNav(true);
    }
    setOverlay((prev) => !prev);
  }

  function handleCrossClick(e: React.MouseEvent) {
    e.stopPropagation();
    toggleCarousel();
  }

  useEffect(() => {
    if (shouldShowOverlay) {
      setOverlay(true);
      globalStore.setNav(false);
    }
  }, [shouldShowOverlay]);

  const overlayContent = (
    <div className={style.overlay} onClick={toggleCarousel}>
      <div className={style.crossIcon} onClick={handleCrossClick} />
      <Carousel images={images} initialIndex={initialIndex} onToggle={toggleCarousel} />
    </div>
  );

  return (
    <>
      {overlay ? ReactDOM.createPortal(overlayContent, document.body) : (
        <div>
          <Carousel images={images} initialIndex={initialIndex} onToggle={toggleCarousel} />
        </div>
      )}
    </>
  );
};

export default ImageCarousel;
