"use client";
import { useState } from "react";
import { useForm } from "react-hook-form";
import style from "@/assets/styling/scss/forms.module.scss";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitARequestSchema } from "@/assets/schemas/";
import { globalStore } from '@/app/stores';

import {
  Input,
  Select,
  TextArea,
  FormattedMessage,
} from "@/components/elements";
import {
  budgetOptions,
  projectTypeOptions,
  quantityOptions,
  installOptions,
  osOptions,
  marketOptions,
  switchingOptions,
} from "@/assets/utils/requestFormOptions";

export default function SubmitARequestForm({ partners }) {
  const { sendMail } = globalStore;
  const [activeId, setActiveId] = useState(1);
  const [projectType, setProjectType] = useState( projectTypeOptions[0]?.value || "");
  const [reasonForSwitching, setReasonForSwitching] = useState("");

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    resolver: zodResolver(SubmitARequestSchema),
  });

  const { register, handleSubmit, formState: { errors }, reset } = methods;

  const onSubmit = (data) => {
    sendMail(data);
  };

  const handleChange = (e) => {
    const id = Number(e.target.value);
    setActiveId(id);
  };

  const handleProjectTypeChange = (e) => {
    const selectedType = e.target.value;
    setProjectType(selectedType);

    reset({
      ...methods.getValues(),
      project_type: selectedType,
    });
  };

  const handleReasonForSwitchingChange = (e) => {
    setReasonForSwitching(e.target.value);
  };

  const partnerOptions = partners.map((partner) => ({ value: partner.id, label: partner.partner.name }));

  const selectedPartner = partners.find((p) => p.id === activeId);

  return (
    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
      {/* Partner Selection */}
      <fieldset>
        <legend>
          <FormattedMessage id="select_partner" />
        </legend>

        <Select
          name={register("select_partner")}
          labelId="select_partner_label"
          options={partnerOptions}
          error={errors.partner?.message}
          handleChange={handleChange}
        />

        {selectedPartner && (
          <div className={style.infoWrapper}>
            <div className={style.info}>
              <p>Partner region:</p>
              <p>{selectedPartner?.partner.region}</p>
            </div>
            <div className={style.info}>
              <p>Partner description:</p>
              <p>{selectedPartner?.partner.description}</p>
            </div>
          </div>
        )}
      </fieldset>

      {/* Company Information */}
      <fieldset>
        <legend>
          <FormattedMessage id="company_information" />
        </legend>
        <Input
          name={register("company_name")}
          labelId="first_name_label"
          placeholderId="first_name_placeholder"
          error={errors.company_name?.message}
        />
        <Input
          name={register("company_website")}
          labelId="company_website"
          placeholderId="company_website_placeholder"
          error={errors.company_website?.message}
        />
        <Input
          name={register("contact_person")}
          labelId="contact_person"
          placeholderId="contact_person_placeholder"
          error={errors.contact_person?.message}
        />
        <Input
          name={register("email")}
          labelId="email_address"
          placeholderId="email_address_placeholder"
          error={errors.email?.message}
        />
        <Input
          name={register("phone")}
          labelId="phone_number"
          placeholderId="phone_number_placeholder"
          error={errors.phone?.message}
        />
      </fieldset>

      {/* Resources */}
      <fieldset>
        <legend>
          <FormattedMessage id="budget_resources" />
        </legend>
        <Select
          name={register("budget")}
          labelId="project_budget"
          options={budgetOptions}
          error={errors.budget?.message}
        />
        <TextArea
          name={register("resources")}
          labelId="available_resources"
          placeholderId="available_resources_placeholder"
          error={errors.resources?.message}
          fullWidth={true}
        />
      </fieldset>

      {/* Project Type Selection */}
      <fieldset>
        <legend>
          <FormattedMessage id="project_type" />
        </legend>
        <Select
          name={register("project_type")}
          labelId="project_type"
          options={projectTypeOptions}
          error={errors.project_type?.message}
          handleChange={handleProjectTypeChange}
          value={projectType} // Controlled value
        />
      </fieldset>

      {/* Project Information */}
      {projectType === "existing_without_sensors" && (
        <fieldset>
          <legend>
            <FormattedMessage id="project_information" />
          </legend>
          <Input
            name={register("project_name")}
            labelId="project_name"
            placeholderId="project_name_placeholder"
            error={errors.project_name?.message}
          />
          <Select
            name={register("quantity")}
            labelId="quantity"
            options={quantityOptions}
            error={errors.quantity?.message}
          />
          <Select
            name={register("budget")}
            labelId="project_budget"
            options={budgetOptions}
            error={errors.budget?.message}
          />
          <TextArea
            name={register("project_description")}
            labelId="project_description"
            placeholderId="project_description_placeholder"
            error={errors.project_description?.message}
            fullWidth={true}
          />
        </fieldset>
      )}

      {projectType === "existing_with_computer" && (
        <fieldset>
          <legend>
            <FormattedMessage id="project_information" />
          </legend>
          <Select
            name={register("install_software")}
            labelId="install_software"
            options={installOptions}
            error={errors.install_software?.message}
          />
          <Select
            name={register("operating_system")}
            labelId="operating_system"
            options={osOptions}
            error={errors.operating_system?.message}
          />
          <TextArea
            name={register("project_description")}
            labelId="project_description"
            placeholderId="project_description_placeholder"
            error={errors.project_description?.message}
            fullWidth={true}
          />
        </fieldset>
      )}

      {projectType === "design_phase" && (
        <fieldset>
          <legend>
            <FormattedMessage id="project_information" />
          </legend>
          <TextArea
            name={register("project_description")}
            labelId="project_description"
            placeholderId="project_description_placeholder"
            error={errors.project_description?.message}
            fullWidth={true}
          />
          <TextArea
            name={register("project_objectives")}
            labelId="project_objectives"
            placeholderId="project_objectives_placeholder"
            error={errors.project_objectives?.message}
            fullWidth={true}
          />
          <Select
            name={register("market_potential")}
            labelId="market_potential"
            options={marketOptions}
            error={errors.market_potential?.message}
          />
        </fieldset>
      )}

      {projectType === "switching_platform" && (
        <fieldset>
          <legend>
            <FormattedMessage id="switching_platform" />
          </legend>
          <Input
            name={register("current_platform")}
            labelId="current_platform"
            placeholderId="current_platform_placeholder"
            error={errors.current_platform?.message}
          />
          <Select
            name={register("reason_for_switching")}
            labelId="reason_for_switching"
            options={switchingOptions}
            error={errors.reason_for_switching?.message}
            handleChange={handleReasonForSwitchingChange}
          />
          {reasonForSwitching === "other" && (
            <TextArea
              name={register("other_reason")}
              labelId="other_reason"
              placeholderId="other_reason_placeholder"
              error={errors.other_reason?.message}
              fullWidth={true}
            />
          )}
        </fieldset>
      )}

      <button type="submit" className={style.submitButton}>
        <FormattedMessage id="submit_a_request" />
      </button>
    </form>
  );
}
