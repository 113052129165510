"use client";

import React, { useEffect, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import styles from '@/assets/styling/scss/accordion.module.scss';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { globalStore } from '@/app/stores';
import { doFetch } from '@/app/utils';
import { observer } from 'mobx-react';

const parseQuestions = async () => {
  const { data: { data } } = await doFetch(`/api/questions`);

  return data.map(({ id, attributes }) => ({
    id,
    question: attributes.question.question,
    answer: attributes.question.answer
  }))
}

const AccordionMenu = observer(() => {
  const { lang } = globalStore;
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    async function getQuestions() {
      const questions = await parseQuestions();
      setQuestions(questions);
    }
    getQuestions();
  }, []);

  return (
    <Accordion.Root
      className={styles.RootQuestions}
      type="single"
      defaultValue="item-1"
      collapsible
    >
      {questions.map(({ id, question, answer }) => (
        <Accordion.Item className={styles.ItemQuestions} value={`item=${id}`} key={id}>
          <AccordionTrigger>
            {question[lang]}
          </AccordionTrigger>
          <AccordionContent>
            {answer[lang]}
          </AccordionContent>
        </Accordion.Item>
      ))}

    </Accordion.Root>
  );
});

const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<typeof Accordion.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className={styles.Header}>
    <Accordion.Trigger
      className={classNames(styles.Trigger, className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ChevronDownIcon className={styles.Chevron} aria-hidden />
    </Accordion.Trigger>
  </Accordion.Header>
));

AccordionTrigger.displayName = "AccordionTrigger";

const AccordionContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof Accordion.Content>
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={classNames(styles.Content, className)}
    {...props}
    ref={forwardedRef}
  >
    <div className={styles.ContentText}>{children}</div>
  </Accordion.Content>
));

AccordionContent.displayName = "AccordionContent";

export default AccordionMenu;
