"use client";
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { docStore } from '@/app/stores';
import style from "@/assets/styling/scss/navbar.module.scss";
import { parseDocs, gatherFilesContentFromParsedDocs } from '@/assets/utils/utils';

const SearchWrapper = () => {
  const [query, setQuery] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const router = useRouter();

  useEffect(() => {
    async function fetchDocs() {
      try {
        const response = await fetch("http://localhost:1337/api/doctree?populate=*");

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const parsedDocs = parseDocs(data);
        const filesData = gatherFilesContentFromParsedDocs(parsedDocs);

        setAllFiles(filesData);
      } catch (error) {
        console.error("Failed to fetch documentation:", error);
      }
    }

    fetchDocs();
  }, []);

  useEffect(() => {
    if (query) {
      const suggestions = allFiles.filter(file =>
        file.content.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredSuggestions(suggestions);
    } else {
      setFilteredSuggestions([]);
      setSelectedIndex(-1);
    }
  }, [query, allFiles]);

  const handleSuggestionClick = (file) => {
    docStore.setSelectedFile(file.content, file.path); 
    router.push('/for-developers/documentation/');
    setQuery('');
    setFilteredSuggestions([]);
    setSelectedIndex(-1);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && filteredSuggestions.length > 0) {
      handleSuggestionClick(filteredSuggestions[selectedIndex]);
    } else if (event.key === "ArrowDown") {
      setSelectedIndex(prevIndex =>
        (prevIndex < filteredSuggestions.length - 1) ? prevIndex + 1 : 0
      );
    } else if (event.key === "ArrowUp") {
      setSelectedIndex(prevIndex =>
        (prevIndex > 0) ? prevIndex - 1 : filteredSuggestions.length - 1
      );
    }
  };

  return (
    <div className={style.searchWrapper}>
      <input
        type="text"
        placeholder="Search in Documentation"
        className={style.search}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <MagnifyingGlassIcon className={style.searchIcon} />
      {filteredSuggestions.length > 0 && (
        <ul className={style.suggestionsList}>
          {filteredSuggestions.map((file, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(file)}
              style={{
                backgroundColor: selectedIndex === index ? 'var(--background-color)' : 'transparent',
                color: selectedIndex === index ? 'var(--accent)' : '#000'
              }}
            >
              {file.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchWrapper;
