import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import { CaretDownIcon } from '@radix-ui/react-icons';
import { FormattedMessage } from '@/components/elements';  // Adjust import if necessary
import styles from '@/assets/styling/scss/navigationmenu.module.scss';

{/* 
  <ListItem href="/for-companies/become-a-partner" title={<FormattedMessage id="become_a_partner" />}>
   <FormattedMessage id="become_a_partner_description" /> 
  </ListItem> 
*/}

const NavigationMenuDemo = () => {
  return (
    <NavigationMenu.Root className={styles.Root}>
      <NavigationMenu.List className={styles.MenuList}>
        <NavigationMenu.Item>
          <NavigationMenu.Trigger className={styles.Trigger}>
            <FormattedMessage id="for_companies" />
            <CaretDownIcon className={styles.CaretDown} aria-hidden />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className={styles.Content}>
            <ul className={`${styles.List} one`}>
              <ListItem href="/for-companies/success-stories" title={<FormattedMessage id="success_stories" />} >
                <FormattedMessage id="success_stories_description" />
              </ListItem>
              <ListItem href="/for-companies/submit-a-request" title={<FormattedMessage id="start_your_project" />} >
                <FormattedMessage id="start_your_project_description" />
              </ListItem>
              <ListItem href="/for-companies/pricing" title={<FormattedMessage id="pricing" />}>
                <FormattedMessage id="pricing_description" />
              </ListItem>
              <ListItem title={<FormattedMessage id="subtitle_voucher" />} href="/for-companies/vouchers">
                <FormattedMessage id="vouchers_description" />
              </ListItem>


            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Trigger className={styles.Trigger}>
            <FormattedMessage id="for_developers" />
            <CaretDownIcon className={styles.CaretDown} aria-hidden />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className={styles.Content}>
            <ul className={`${styles.List} two`}>
              <ListItem title={<FormattedMessage id="bluecherry" />} href="/for-developers/bluecherry">
                <FormattedMessage id="bluecherry_description" />
              </ListItem>

              <ListItem title={<FormattedMessage id="download_bluecherry_client" />} href="/for-developers/download-bluecherry-client">
                <FormattedMessage id="download_bluecherry_client_description" />
              </ListItem>

              <ListItem title={<FormattedMessage id="documentation" />} href="/for-developers/documentation">
                <FormattedMessage id="documentation_description" />
              </ListItem>

              <ListItem title={<FormattedMessage id="subtitle_discord" />} href="https://www.discord.com" isExternal={true}>
                <FormattedMessage id="discord_description" />
              </ListItem>
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Indicator className={styles.Indicator}>
          <div className={styles.Arrow} />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>

      <div className={styles.ViewportPosition}>
        <NavigationMenu.Viewport className={styles.Viewport} />
      </div>
    </NavigationMenu.Root>
  );
};

const ListItem = React.forwardRef(
  ({ className, children, title, href, isExternal, ...props }, forwardedRef) => (
    <li>
      <NavigationMenu.Link asChild>
        <a
          className={classNames(styles.ListItemLink, className)}
          href={href}
          target={isExternal ? "_blank" : undefined} 
          rel={isExternal ? "noopener noreferrer" : undefined} 
          {...props}
          ref={forwardedRef}
        >
          <div className={styles.ListItemHeading}>{title}</div>
          <p className={styles.ListItemText}>{children}</p>
        </a>
      </NavigationMenu.Link>
    </li>
  )
);

export default NavigationMenuDemo;
