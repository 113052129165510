import style from '@/assets/styling/scss/homepage.module.scss';
import Marquee from 'react-fast-marquee';
import Image from 'next/image';

const MarqueeCarousel = () => {

  const logos = [
    '/images/partners/birthwatch.svg',
    '/images/partners/came.svg',
    '/images/partners/climaworld.png',
    '/images/partners/dbmatic.svg',
    '/images/partners/faraconnect.svg',
    '/images/partners/jifeline-v3.png',
    '/images/partners/mecodo.png',
    '/images/partners/multicalor.png',
    '/images/partners/omnic.png',
    '/images/partners/quickspot.png',
    '/images/partners/rovin.png',
  ];

  return (
    <Marquee className={style.marquee} pauseOnHover gradient gradientColor="var(--accent)" speed={15}>
      {logos.map((logo, index) => (
        <Image key={index} src={logo} alt={`Partner logo ${index + 1}`} className={style.marqueeLogo} width="120" height="60"/>
      ))}
    </Marquee>

  )
}

export default MarqueeCarousel;
