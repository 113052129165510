"use client"
import raw_locales from '@/assets/locales/locales.json';
const locales = raw_locales as { [key: string]: { [key: string]: string } }
import { useState, useEffect } from "react";

export const doFetch = async (url: string, method: string = 'GET', data?: any): Promise<{ status: number, data: any }> => {
  try {
    const options: RequestInit = {
      method,
      mode: 'same-origin',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: method !== 'GET' ? JSON.stringify(data) : undefined,
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const parsedData = await response.json();

    return {
      status: response.status,
      data: parsedData,
    };
  } catch (error) {
    console.error('Fetch error:', error);
    return {
      status: error.status || 500,
      data: null,
    };
  }
};

export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    const handleChange = () => setMatches(mediaQuery.matches);

    handleChange();
    mediaQuery.addEventListener('change', handleChange);

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [query]);

  return matches;
};

export const getCookie = (name: string): string | null => {
  if (typeof document === 'undefined') return null;
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
};

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const translate = (
  lang: string | null,
  id: string,
  replacements?: { [key: string]: string }
): string => {
  lang = lang?.toLowerCase() || getCookie('site_lang')?.toLowerCase() || 'en';
  const idMatch = locales[id];

  if (idMatch) {
    const langMatch = idMatch[lang];

    if (langMatch) {
      if (replacements) {
        return Object.keys(replacements).reduce(
          (translation, key) =>
            translation.replace(new RegExp(`{${escapeRegExp(key)}}`, 'g'), replacements[key]),
          langMatch
        );
      }
      return langMatch;
    }
  }

  addMissingTranslation(id);
  return id;
};

const addMissingTranslation = (id: string): void => {
  if (typeof window === 'undefined') return; // Ensure running in the browser

  const json = localStorage.getItem('missing_translations');
  const translations: string[] = json ? JSON.parse(json) : [];

  if (!translations.includes(id)) {
    translations.push(id);
    localStorage.setItem('missing_translations', JSON.stringify(translations));
  }
};


export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState("up");
  const [lastScrollY, setLastScrollY] = useState(0);
  const [lastChangeTime, setLastChangeTime] = useState(Date.now());
  const [timeoutId, setTimeoutId] = useState(null);
  const threshold = 66;
  const delay = 150;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const now = Date.now();

      if (Math.abs(currentScrollY - lastScrollY) > threshold) {
        if (currentScrollY > lastScrollY) {
          setScrollDirection("down");
        } else {
          setScrollDirection("up");
        }
        setLastScrollY(currentScrollY);
        setLastChangeTime(now);

        if (timeoutId) clearTimeout(timeoutId);
        const id = setTimeout(() => {
          if (now - lastChangeTime >= delay) {
            setScrollDirection(currentScrollY > lastScrollY ? "down" : "up");
          }
        }, delay);
        setTimeoutId(id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY, lastChangeTime, threshold, delay, timeoutId]);

  return scrollDirection;
};

export const formatFileName = (name: string): string => {
  return name.replace(/\.md$/, '');
}

export const parseDocs = (docs: any): NavItem[] => {
  function buildNav(items: any[], parentPath: string = ''): NavItem[] {
    return items.map(item => {
      const currentPath = parentPath ? `${parentPath}/${item.name}` : item.name;
      if (item.type === 'directory') {
        return {
          name: formatFileName(item.name), 
          type: item.type,
          path: currentPath, 
          children: buildNav(item.children || [], currentPath),
        };
      }
      return {
        name: formatFileName(item.name), 
        type: 'file',
        path: currentPath, 
        content: item.content,
      };
    });
  }

  return buildNav(docs);
};

export const gatherFilesContentFromParsedDocs = (docs: NavItem[]): FileData[] => {
  const filesData: FileData[] = [];

  docs.forEach(item => {
    if (item.type === 'file') {
      filesData.push({
        name: item.name,
        content: item.content,
        path: item.path
      });
    } 
    else if (item.type === 'directory' && item.children) {
      filesData.push(...gatherFilesContentFromParsedDocs(item.children));
    }
  });

  return filesData;
};

export function debounce(fn: Function, delay: number) {
  let timerId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
}

export const useLockScroll = (isLocked) => {
  useEffect(() => {
    if (isLocked) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isLocked]);
};

