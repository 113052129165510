"use client"
import { useEffect, useState } from "react";
import { FormattedMessage, GoBack, AccordionQuestions } from "@/components/elements";
import { NavBar, Footer } from "@/components/partials";
import style from "@/assets/styling/scss/pricing.module.scss";
import Link from 'next/link';
import { paperVariants } from "@/assets/utils/motion";
import { motion } from 'framer-motion';

const plans = [
  {
    price: "BlueCherry",
    plan: "Features",
    planText: "bluecherry_features",
    lists: [
      "bluecherry_feature_1",
      "bluecherry_feature_2",
      "bluecherry_feature_3",
      "bluecherry_feature_4",
      "bluecherry_feature_5",
      "bluecherry_feature_6",
    ],
  },
  // plans
  {
    price: "€40",
    priceLabel: 'plus_one_euro_per_device',
    period: "period_month",
    plan: "Edge Launch",
    planText: "edge_launch_text",
    lists: [
      "bluecherry_features_title",
      "device_type",
      "connect_10",
      "edge_launch_feature",
    ],
    link: {
      link: '/for-developers/bluecherry#iot',
      linkText: 'Full Devices'
    },
    button: "submit_your_project",
  },
  {
    price: "€75",
    priceLabel: '',
    period: "period_month",
    plan: "Cloud Launch",
    planText: "cloud_solution_fleet",
    lists: [
      "bluecherry_features_title",
      "device_type",
      "connect_10",
    ],
    link: {
      link: '/for-developers/bluecherry#iot',
      linkText: 'Lite Devices'
    },
    button: "submit_your_project",
  },
  {
    price: "€0",
    priceLabel: 'three_months_free',
    period: "period_month",
    plan: "Discovery",
    planText: "explore_platform_connect_devices",
    lists: [
      "bluecherry_features_title",
      "device_type",
      "connect_10",
      "one_month_transfer_period",
      "auto_deletion_after_4_months",
    ],
    button: "create_a_user_account",
  },
  {
    price: "€150",
    priceLabel: 'plus_one_euro_per_device',
    period: "period_month",
    plan: "Edge",
    planText: "full_control_solution_security",
    lists: [
      "bluecherry_features_title",
      "device_type",
      "connect_1000",
      "edge_launch_feature",
    ],
    link: {
      link: '/for-developers/bluecherry#iot',
      linkText: 'Full Devices'
    },
    options: ['personal_branding', 'multitenant'],
    button: "submit_your_project",
  },
  {
    price: "€250",
    priceLabel: '',
    period: "period_month",
    plan: "Cloud",
    planText: "optimal_cloud_solution",
    lists: [
      "bluecherry_features_title",
      "device_type",
      "connect_1000",
    ],
    link: {
      link: '/for-developers/bluecherry#iot',
      linkText: 'Lite Devices'
    },
    options: ['personal_branding', 'multitenant'],
    button: "submit_your_project",
  },
]

const PriceCard = ({ plan }) => {
  const {
    price,
    priceLabel,
    period,
    plan: planTitle,
    planText,
    lists,
    button,
    link,
    options,
  } = plan;

  return (
    <article className={price.includes("Blue") ? style.priceCardSecondary : style.priceCard}>
      {priceLabel && (
        <p className={ priceLabel.includes("three") ? style.priceLabel : style.priceLabelGreen }>
          <FormattedMessage id={priceLabel} />
        </p>
      )}
      {priceLabel === "" && <p className={style.priceLabelInvisible} />}
      <h2 className={!price.includes("€0") ? style.subtitle : style.subtitleBlue}>
        {price}
        <span>
          <FormattedMessage id={period} />
        </span>
      </h2>
      <h1 className={style.title}>{planTitle}</h1>
      <p className={style.text}>
        <FormattedMessage id={planText} />
        {link && (
          <Link href={link.link} className={style.link}>
            {link.linkText}
          </Link>
        )}
      </p>
      <ul className={style.featureList}>
        {Array.isArray(lists) && lists.map((item, index) => (
          <li key={index}>
            <FormattedMessage id={item} />
          </li>
        ))}
      </ul>

      {Array.isArray(options) && options.map((item, index) => (
        <p key={index} className={style.featureOption}>
          <span>
            <FormattedMessage id="option" />
          </span>
          <FormattedMessage id={item} />
        </p>
      ))}

      {button && (
        <a className={button.includes("submit") ? style.buttonSecondary : style.button} href={button.includes("submit") ? "/for-companies/submit-a-request" : "https://sso.bluecherry.io/register"}>
          <FormattedMessage id={button} />
        </a>
      )}
    </article>
  );
};

const Pricing = () => {
  const [isWideScreen, setIsWideScreen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1024);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <NavBar />
      <main>
        <motion.section
          className={style.content}
          variants={paperVariants}
          initial="hidden"
          animate={["visible", "scale", "translateY"]}
          exit="exit"
        >
          <GoBack />
          <h1>
            <FormattedMessage id="subtitle_pricing" />
          </h1>
          <h2>
            <FormattedMessage id="appropriate_pricing" />
          </h2>

          <section className={style.priceWrapper}>
            {isWideScreen && <div></div>}
            {plans.length > 0 &&
              plans.map((plan, index) => <PriceCard key={index} plan={plan} />)}
          </section>

          <section className={style.talk}>
            <FormattedMessage id="need_custom_pricing_plan" />{" "}
            <Link href="tel:050622888" className={style.link}>
              <FormattedMessage id="lets_talk" />
            </Link>
          </section>

          <h1 className={style.questionTitle}>
            <FormattedMessage id="questions" />
          </h1>
          <h2>
            <FormattedMessage id="mail_question" />
          </h2>
          <div className={style.buttonWrapper}>
            <AccordionQuestions />
            <a
              className={style.buttonQuestions}
              href="mailto:info.bluecherry.io"
            >
              <FormattedMessage id="ask_question_via_mail" />
            </a>
          </div>
        </motion.section>
      </main>
      <Footer />
    </>
  );
};

export default Pricing;
