import { z } from "zod";
import { translate, getCookie } from "@/assets/utils/utils";

const getMessage = (value) => {
  const lang = getCookie("site_lang") || "en";
  return translate(lang, value);
};

const baseSchema = z.object({
  company_name: z
    .string()
    .nonempty({ message: getMessage("name_validation") })
    .max(50, { message: getMessage("name_validation") }),

  company_website: z
    .string()
    .refine((value) => /^(?=.*\.[a-z]{2,})[^\s$.?#].[^\s]*$/i.test(value), {
      message: getMessage('url_validation'),
    }),

  contact_person: z
    .string()
    .nonempty({ message: getMessage("contact_person_validation") })
    .max(50, { message: getMessage("contact_person_validation") }),

  email: z.string().email({ message: getMessage("email_validation") }),

  phone: z
    .string()
    .nonempty({ message: getMessage("phone_validation") })
    .min(10, { message: getMessage("phone_validation") })
    .max(15, { message: getMessage("phone_validation") }),

  budget: z
    .string()
    .nonempty({ message: getMessage("budget_validation") }),

  resources: z
    .string()
    .nonempty({ message: getMessage("resources_validation") }),

  project_type: z
    .string()
    .nonempty({ message: getMessage("project_type_validation") })
});

export const SubmitARequestSchema = z.object({
  ...baseSchema.shape,

  // Conditional fields
  project_description: z.string().optional(),
  project_objectives: z.string().optional(),
  market_potential: z.string().optional(),

  current_platform: z.string().optional(),
  reason_for_switching: z.string().optional(),
  other_reason: z.string().optional(),

  project_name: z.string().optional(),
  quantity: z.string().optional(),

  install_software: z.string().optional(),
  operating_system: z.string().optional()
}).refine(data => {
  switch (data.project_type) {
    case "existing_without_sensors":
      return !!data.project_name && !!data.quantity && !!data.budget;
    case "existing_with_computer":
      return !!data.install_software && !!data.operating_system;
    case "design_phase":
      return !!data.project_description && !!data.project_objectives && !!data.market_potential;
    case "switching_platform":
      return !!data.current_platform && !!data.reason_for_switching && (data.reason_for_switching !== 'other' || !!data.other_reason);
    default:
      return true;
  }
}, {
  message: getMessage("project_type_validation"),
  path: ["project_type"],
});
