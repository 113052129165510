import style from '@/assets/styling/scss/input.module.scss';
import { FC } from 'react';
import FormattedMessage from '@/components/elements/FormattedMessage';

interface SelectProps {
  name: string;
  labelId: string;
  options: { value: string; label: string }[];
  error?: string;
}

const Select: FC<SelectProps> = ({
  name,
  labelId,
  options,
  error,
  handleChange
}) => {

  return (
    <div className={style.inputWrapper}>
      <label className={style.label}>
        <FormattedMessage id={labelId} />
      </label>

      <div className={style.inputContainer}>
        <select
          {...name}
          name={name}
          className={style.select}
          onChange={handleChange}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {error && <p className={style.error}>{error}</p>}
    </div>
  );
};

export default Select;
